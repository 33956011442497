import { createAction } from 'redux-actions';
import dayjs from 'dayjs';
import { find, get } from 'lodash';

import * as MODALS from 'constants/modals';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { Astrologist, HistoryItem } from 'api/astrology-chat/constants';
import { showModal } from 'store/modals/actions';
import { setUserParams } from 'store/profile/actions';
import { AdvisorHoroscopePeriods } from 'screens/advisors/constants';
import { loadUserHoroscopeV2 } from 'store/horoscope/actions';
import { HOROSCOPE_REQUEST_STATUSES } from 'store/horoscope/constants';
import { selectUserHoroscopeV2 } from 'store/horoscope/selectors';
import { AdvisorHoroscopeContent } from 'interfaces/horoscope';
import { HoroscopeTypes } from 'constants/horoscopes-names';
import { formatTextWithoutTags } from 'utils/text-format-replace-tags';
import { getUserHoroscopeV2ById } from 'api/horoscope';
import { addNotificationToQueue, removeNotificationFromQueue } from 'store/notification-center/actions';
import { NOTIFICATIONS } from 'constants/notification-center';

import { selectActiveHoroscopeSubscription, selectAdvisorHoroscopeContentByPeriod } from '../selectors';
import { addNewMessage, addPendingChat, getHistoryMessages } from '../chat/actions';

import { ADVISOR_HOROSCOPE_SUB_STATUSES, AdvisorHoroscopeSubData, TYPES } from './types';
import { defaultHoroscopeIds } from './constants';

export const setHoroscopeSubscriptionData = createAction(TYPES.SET_SUBSCRIPTIONS_DATA);
export const setHoroscopeTimerEnabled = createAction(TYPES.SET_HOROSCOPE_TIMER_ENABLED);
export const setSubscriptionsDataIsUpdated = createAction(TYPES.SET_DATA_IS_UPDATED);
export const setHoroscopeContent = createAction(TYPES.SET_HOROSCOPE_CONTENT);
export const setNeedResetCache = createAction(TYPES.SET_NEED_RESET_CACHE);

export const subscribeToAdvisorHoroscope = (id: Astrologist['astrologer_id']): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const activeHoroscopeSubscription = selectActiveHoroscopeSubscription(getState());
    const newSubscriptionData: AdvisorHoroscopeSubData = {
      id,
      status: ADVISOR_HOROSCOPE_SUB_STATUSES.PENDING,
      lastUpdatedAt: dayjs().format(),
      horoscopeIds: defaultHoroscopeIds,
      isViewed: true,
    };

    if (activeHoroscopeSubscription) {
      dispatch(setHoroscopeSubscriptionData([activeHoroscopeSubscription, newSubscriptionData]));
    } else {
      dispatch(setHoroscopeSubscriptionData([newSubscriptionData]));
    }

    dispatch(setUserParams({ horoscopes_advisor_id: id }));
    dispatch(updateHoroscopeSubUserParams());
    dispatch(setHoroscopeTimerEnabled(true));
  };
};

export const setHoroscopeSubscriptionActiveStatus = (id: Astrologist['astrologer_id']): AppThunk => {
  return (dispatch: AppDispatch) => {
    const newActiveSubscriptionData: AdvisorHoroscopeSubData = {
      id,
      status: ADVISOR_HOROSCOPE_SUB_STATUSES.ACTIVE,
      lastUpdatedAt: dayjs().format(),
      horoscopeIds: defaultHoroscopeIds,
      isViewed: false,
    };

    dispatch(setNeedResetCache(true));
    dispatch(getHistoryMessages(id));
    dispatch(addPendingChat(id));
    dispatch(setHoroscopeSubscriptionData([newActiveSubscriptionData]));
    dispatch(setUserParams({ horoscopes_advisor_id: id }));
    dispatch(updateHoroscopeSubUserParams());
    dispatch(addNotificationToQueue(NOTIFICATIONS.ADVISOR_HOROSCOPE));
  };
};

export const changeHoroscopeIsViewedStatus = (id: Astrologist['astrologer_id'], isViewed: boolean): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const subscriptions = getState().astrologers.horoscope.subscriptions;

    const newSubscriptionsList = subscriptions.map(subscription => {
      if (subscription.id === id) {
        return {
          ...subscription,
          isViewed,
        };
      }
      return subscription;
    });

    if (isViewed) {
      dispatch(removeNotificationFromQueue(NOTIFICATIONS.ADVISOR_HOROSCOPE));
    }
    dispatch(setHoroscopeSubscriptionData(newSubscriptionsList));
    dispatch(updateHoroscopeSubUserParams());
  };
};

export const changeHoroscopeSubscriptionModal = (id: Astrologist['astrologer_id']): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      core: { allAstrologers },
      horoscope: { subscriptions },
    } = getState().astrologers;

    if (subscriptions.length) {
      const newAdvisor = allAstrologers.find(astrologer => astrologer.astrologer_id === id);
      const pendingSubscription = subscriptions.find(subscription => subscription.status === ADVISOR_HOROSCOPE_SUB_STATUSES.PENDING);

      let prevAdvisorData: Astrologist;

      if (pendingSubscription) {
        prevAdvisorData = allAstrologers.find(astrologer => astrologer.astrologer_id === pendingSubscription.id) || allAstrologers[0];
      } else {
        prevAdvisorData = allAstrologers.find(astrologer => astrologer.astrologer_id === subscriptions[0].id) || allAstrologers[0];
      }

      dispatch(
        showModal(MODALS.CHANGE_ADVISOR_SUBSCRIPTION, {
          prevAdvisor: prevAdvisorData,
          newAdvisor: newAdvisor,
        }),
      );
    }
  };
};

export const updateAdvisorSubscriptionData = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const activeSubscription = selectActiveHoroscopeSubscription(getState());

    if (activeSubscription) {
      if (dayjs().isAfter(dayjs(activeSubscription.lastUpdatedAt), 'day')) {
        dispatch(
          setHoroscopeSubscriptionData([
            { ...activeSubscription, isViewed: false, lastUpdatedAt: dayjs().format(), horoscopeIds: defaultHoroscopeIds },
          ]),
        );
        dispatch(updateHoroscopeSubUserParams());
      }

      dispatch(checkNotificationIsNeeded());
      dispatch(getHistoryMessages(activeSubscription.id));
      dispatch(addPendingChat(activeSubscription.id));
    }

    dispatch(setSubscriptionsDataIsUpdated());
  };
};

export const checkHoroscopeSubscriptionStatuses = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { advisorHoroscopeEnabled },
      astrologers: {
        horoscope: { subscriptions },
      },
    } = getState();

    if (advisorHoroscopeEnabled && subscriptions.length) {
      const isPendingSubscription = subscriptions.find(subscription => subscription.status === ADVISOR_HOROSCOPE_SUB_STATUSES.PENDING);

      if (isPendingSubscription) {
        if (dayjs().isAfter(dayjs(isPendingSubscription.lastUpdatedAt), 'day')) {
          dispatch(setHoroscopeSubscriptionActiveStatus(isPendingSubscription.id));
        } else {
          dispatch(setHoroscopeTimerEnabled(true));
        }
      }
    }

    dispatch(updateAdvisorSubscriptionData());
  };
};

export const checkNotificationIsNeeded = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const activeSubscription = selectActiveHoroscopeSubscription(getState());

    if (activeSubscription && !activeSubscription.isViewed) {
      dispatch(addNotificationToQueue(NOTIFICATIONS.ADVISOR_HOROSCOPE));
    }
  };
};

export const updateHoroscopeSubUserParams = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const subscriptions = getState().astrologers.horoscope.subscriptions;
    const data = subscriptions.length ? JSON.stringify(subscriptions) : null;
    dispatch(setUserParams({ horoscopes_subscriptions_data: data }));
  };
};

export const updateHoroscopeIds = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const activeSubscription = selectActiveHoroscopeSubscription(state);
    const { horoscopeContent, subscriptions } = state.astrologers.horoscope;

    if (activeSubscription) {
      const horoscopeIds = Object.keys(horoscopeContent).reduce((acc, period) => {
        return {
          ...acc,
          [period]: horoscopeContent[period]?.id || null,
        };
      }, {});

      const restSubscription = subscriptions.filter(sub => sub.id !== activeSubscription.id);
      dispatch(setHoroscopeSubscriptionData([...restSubscription, { ...activeSubscription, horoscopeIds }]));
      dispatch(updateHoroscopeSubUserParams());
    }
  };
};

export const cancelHoroscopeSubscription = (): AppThunk => {
  return (dispatch: AppDispatch) => {
    dispatch(setHoroscopeSubscriptionData([]));
    dispatch(setUserParams({ horoscopes_advisor_id: null }));
    dispatch(updateHoroscopeSubUserParams());
  };
};

/* Loading and processing horoscopes content data */
export const loadAdvisorHoroscope = (period: AdvisorHoroscopePeriods): AppThunk<Promise<AdvisorHoroscopeContent | null>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const activeSubscription = selectActiveHoroscopeSubscription(state);

    if (activeSubscription) {
      if (activeSubscription.horoscopeIds[period]) {
        const savedContent = await requestAdvisorHoroscopeById(activeSubscription.horoscopeIds[period]!, period);

        if (savedContent) {
          dispatch(setHoroscopeContent({ period, content: savedContent }));
          return savedContent;
        }
      }

      const content = await dispatch(requestAdvisorHoroscope(period));

      if (content) {
        dispatch(setHoroscopeContent({ period, content }));
        return content;
      }

      const defaultContent = dispatch(getDefaultHoroscopeContent(period));
      dispatch(setHoroscopeContent({ period, content: defaultContent }));
      return defaultContent;
    }

    return null;
  };
};

export const getDefaultHoroscopeContent = (period: AdvisorHoroscopePeriods): AppThunk<AdvisorHoroscopeContent> => {
  return (_dispatch, getState: AppGetState) => {
    const horoscopeContent: AdvisorHoroscopeContent = {
      id: null,
      focus: '',
      troubles: '',
      period,
      textBlocks: [],
      sourceVersion: 'v1',
    };

    const userHoroscopes = getState().horoscope.userHoroscopes;

    const defaultHoroscope = userHoroscopes[HoroscopeTypes.zodiac].length
      ? find(userHoroscopes[HoroscopeTypes.zodiac], { time_type: period.toLowerCase() }) || null
      : null;

    if (defaultHoroscope) {
      return {
        ...horoscopeContent,
        focus: get(defaultHoroscope, 'focus', ''),
        textBlocks: [{ title: '', text: formatTextWithoutTags(get(defaultHoroscope, 'text', '')) }],
      };
    }
    return horoscopeContent;
  };
};

export const requestAdvisorHoroscope = (period: AdvisorHoroscopePeriods): AppThunk<Promise<AdvisorHoroscopeContent | null>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const response = await dispatch(loadUserHoroscopeV2(period));

    if (response === HOROSCOPE_REQUEST_STATUSES.SUCCESS || response === HOROSCOPE_REQUEST_STATUSES.DATA_ALREADY_LOADED) {
      const horoscope = selectUserHoroscopeV2(getState(), period);

      if (horoscope) {
        return {
          id: horoscope.id,
          sourceVersion: 'v2',
          period,
          focus: horoscope.data.powerAndFocus,
          troubles: horoscope.data.troubles,
          textBlocks: horoscope.data.blocks,
        };
      }
    }
    return null;
  };
};

export const requestAdvisorHoroscopeById = async (id: number, period: AdvisorHoroscopePeriods): Promise<AdvisorHoroscopeContent | null> => {
  const response = await getUserHoroscopeV2ById(id);
  if (response.length) {
    return {
      id: response[0].id,
      sourceVersion: 'v2',
      period,
      focus: response[0].data.powerAndFocus,
      troubles: response[0].data.troubles,
      textBlocks: response[0].data.blocks,
    };
  }
  return null;
};

export const getAdvisorHoroscopeContent = (period: AdvisorHoroscopePeriods): AppThunk<Promise<AdvisorHoroscopeContent>> => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const horoscopeContent = selectAdvisorHoroscopeContentByPeriod(state, period);

    if (horoscopeContent) {
      return horoscopeContent;
    }

    const content = await dispatch(loadAdvisorHoroscope(period));
    if (content) {
      return content;
    }

    const defaultContent = dispatch(getDefaultHoroscopeContent(period));
    return defaultContent;
  };
};

export const sendFakeRequestToGetHoroscope = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const activeSubscription = selectActiveHoroscopeSubscription(getState());

    if (activeSubscription) {
      const message: HistoryItem = {
        id: Math.random().toString(16),
        type: 'question',
        message: 'Вези мне гороскопы, астролог, меня люди ждут!!!',
        created_at: dayjs.utc(dayjs()).format('YYYY-MM-DD HH:mm:ss'),
        is_viewed: 0,
        is_answered: 1,
        animated: false,
        is_viewed_by_user: 1,
        metadata: { type: 'horoscopeRequest' },
      };

      dispatch(addNewMessage(activeSubscription.id, [message]));
    }
  };
};
