import { Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';

import { isStage } from 'constants/general';

export const getApiUrl = (): string => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_API_BASE_URL || '';
  }
  return isStage() ? 'https://astrology-stage.astroline.app/api/v1' : 'https://astrology.astroline.app/api/v1';
};

const isLogEnabled = false;

const api = {
  token: '',

  setToken(newToken: string) {
    this.token = newToken;
  },

  fetch(url: string, options: any, pureBodyData = false) {
    const body = options.data ? (pureBodyData ? options.data : JSON.stringify(options.data)) : undefined;

    return fetch(`${getApiUrl()}${url}`, {
      ...options,
      body,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${this.token}` || undefined,
        'Content-Type': 'application/json; charset=UTF-8',
        ...options.headers,
        'X-platform': Platform.OS,
        'X-version': DeviceProps.getAppVersion(),
      },
    })
      .then(response => {
        return response.json().then(r => {
          if (response.ok) {
            return r;
          } else {
            throw r;
          }
        });
      })
      .catch(err => {
        if (isLogEnabled) {
          console.warn('Network Response:', err);
        }
        throw err;
      });
  },
};

export default api;
