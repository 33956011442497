import api from 'api';

import { ACCRUE_TIME } from './constants';

const accrueTime = async (time: number): Promise<undefined> => {
  try {
    const response = await api.fetch(ACCRUE_TIME, {
      method: 'POST',
      data: {
        time,
        type: 'cycle',
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] accrueFreeTime', error);
  }
};

export default accrueTime;
