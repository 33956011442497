import React, { FC, memo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import * as COLORS from 'constants/colors';
import Text from 'components/text';
import Icon from 'components/icon';

import { ProLabelSizes, sizes } from './constants';

const PRO_TITLE = 'PRO';

interface Props {
  size: ProLabelSizes;
  title?: string;
  style?: StyleProp<ViewStyle>;
}

const ProLabel: FC<Props> = ({ title = PRO_TITLE, size, style = null }) => {
  const configSizes = sizes[size];

  return (
    <View style={[styles.root, { minWidth: configSizes.width, height: configSizes.height, borderRadius: configSizes.borderRadius }, style]}>
      <Icon
        style={[styles.star, { left: configSizes.leftStarLeftIndent, bottom: configSizes.leftStarBottomIndent }]}
        name={'star-pro'}
        size={configSizes.leftStar}
        gradientPreset={'light-gold'}
      />
      <Icon
        style={[styles.star, { top: configSizes.rightStarTopIndent, right: configSizes.rightStarRightIndent }]}
        name={'star-pro'}
        size={configSizes.rightStar}
        gradientPreset={'light-gold'}
      />
      <Text style={[styles.title, { fontSize: configSizes.textSize }]}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.LIGHT_MUSTARD,
  },
  title: {
    fontWeight: '600',
    color: COLORS.DARK_BLUE_GREY_2,
    textAlign: 'center',
  },
  star: {
    position: 'absolute',
  },
});

export default memo(ProLabel);
