export const PALMISTRY_GET_RESULT = 'palmistry_get_result';

export const CALENDARS = 'calendars';

export const BEST_SIGN_MATCHES = 'best_sign_matches';
export const GUIDE_SESSIONS = 'guide_sessions';
export const LUNAR_CALENDAR = 'lunar_calendar';

export const TRANSITS = 'transits';
export const CHARTS = 'charts';
export const PLANETS = 'planets';
export const HOUSES = 'houses';

export const DIAMOND = 'diamond';
export const APP_FOREGROUND = 'app_foreground';
export const REACTIVATION = 'reactivation';

// NEWEST
// POSTFIXES
export const POSTFIXES = {
  OPEN: 'open',
  TAB: 'tab',
  SIGN: 'sign',
  GUIDE_BANNER: 'guide_banner',
  BANNER: 'banner',
  EXPAND: 'expand',
  GET_RESULT: 'get_result',
  SWITCH: 'switch',
  OTHER_PLANS: 'other_plans',
};

export enum TABS_TYPES {
  TOMORROW = 'tomorrow',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const WHERE_TO_NAVIGATE = 'where_to_navigate';
export const TABS = 'tabs';

// [OTHERS_PLANS] START
export const REACTIVATION_OTHER_PLANS = `${REACTIVATION}_${POSTFIXES.OTHER_PLANS}`;
// [OTHERS_PLANS] END

// [SETTINGS] START
export const SETTINGS = 'setting';
export const SETTINGS_BANNER = `${SETTINGS}_${POSTFIXES.BANNER}`;
// [SETTINGS] END

// [DASHBOARD] START

// [PERSONAL READING] START
export const PERSONAL_READING = 'personal_reading';
export const PERSONAL_READING_OPEN = `${PERSONAL_READING}_${POSTFIXES.OPEN}`;
export const PERSONAL_READING_SWITCH = `${PERSONAL_READING}_${POSTFIXES.SWITCH}`;

export const PERSONAL_READING_TABS = {
  [TABS_TYPES.TOMORROW]: `${PERSONAL_READING}_${POSTFIXES.TAB}_${TABS_TYPES.TOMORROW}`,
  [TABS_TYPES.WEEKLY]: `${PERSONAL_READING}_${POSTFIXES.TAB}_${TABS_TYPES.WEEKLY}`,
  [TABS_TYPES.MONTHLY]: `${PERSONAL_READING}_${POSTFIXES.TAB}_${TABS_TYPES.MONTHLY}`,
  [TABS_TYPES.YEARLY]: `${PERSONAL_READING}_${POSTFIXES.TAB}_${TABS_TYPES.YEARLY}`,
};

// [PERSONAL READING] END

// [PALM READING] START
export const PALM_READING = 'palm_reading';
export const PALM_READING_OPEN = `${PALM_READING}_${POSTFIXES.OPEN}`;
export const PALM_READING_BANNER = `${PALM_READING}_${POSTFIXES.GUIDE_BANNER}`;
export const PALM_READING_GET_RESULT = `${PALM_READING}_${POSTFIXES.GET_RESULT}`;
// [PALM READING] START

// [STORIES] START
export const STORIES = 'stories';
export const STORIES_OPEN = `${STORIES}_${POSTFIXES.OPEN}`;
export const STORIES_NEXT_CATEGORY = `${STORIES}_next_category`;
// [STORIES] END

// [BIORHYTHM] START
export const BIORHYTHM = 'biorhythm';
export const BIORHYTHM_OPEN = `${BIORHYTHM}_${POSTFIXES.OPEN}`;
export const BIORHYTHM_TAB = `${BIORHYTHM}_${POSTFIXES.TAB}`;
export const BIORHYTHM_BANNER = `${BIORHYTHM}_${POSTFIXES.GUIDE_BANNER}`;
// [BIORHYTHM] END

// [LUNAR] START
export const LUNAR = 'lunar';
export const LUNAR_OPEN = `${LUNAR}_${POSTFIXES.OPEN}`;
export const LUNAR_TAB = `${LUNAR}_${POSTFIXES.TAB}`;
// [LUNAR] END

// [FEATURES] START
export const FEATURES = 'features';
export const FEATURES_OPEN = `${FEATURES}_${POSTFIXES.OPEN}`;
// [FEATURES] END

// [DATING_CALENDAR] START
export const DATING_CALENDAR = 'dating_calendar';
export const DATING_CALENDAR_OPEN = `${DATING_CALENDAR}_${POSTFIXES.OPEN}`;
export const DATING_CALENDAR_TAB = `${DATING_CALENDAR}_${POSTFIXES.TAB}`;
export const DATING_CALENDAR_BANNER = `${DATING_CALENDAR}_${POSTFIXES.GUIDE_BANNER}`;
// [DATING_CALENDAR] END

// [FEATURES] START
export const MATCHES = 'matches';
export const MATCHES_OPEN = `${MATCHES}_${POSTFIXES.OPEN}`;
// [FEATURES] END

// [ALT_HOROSCOPES] START
export const ALT_HOROSCOPES = 'alt_horoscopes';
export const ALT_HOROSCOPES_INDIAN = `${ALT_HOROSCOPES}_indian`;
export const ALT_HOROSCOPES_INDIAN_LUNAR = `${ALT_HOROSCOPES_INDIAN}_lunar`;
export const ALT_HOROSCOPES_INDIAN_LUNAR_OPEN = `${ALT_HOROSCOPES_INDIAN_LUNAR}_${POSTFIXES.OPEN}`;
export const ALT_HOROSCOPES_INDIAN_LUNAR_TAB = `${ALT_HOROSCOPES_INDIAN_LUNAR}_${POSTFIXES.TAB}`;
export const ALT_HOROSCOPES_INDIAN_LUNAR_SIGN = `${ALT_HOROSCOPES_INDIAN_LUNAR}_${POSTFIXES.SIGN}`;
export const ALT_HOROSCOPES_INDIAN_LUNAR_EXPAND = `${ALT_HOROSCOPES_INDIAN_LUNAR}_${POSTFIXES.EXPAND}`;

export const ALT_HOROSCOPES_INDIAN_SOLAR = `${ALT_HOROSCOPES_INDIAN}_solar`;
export const ALT_HOROSCOPES_INDIAN_SOLAR_OPEN = `${ALT_HOROSCOPES_INDIAN_SOLAR}_${POSTFIXES.OPEN}`;
export const ALT_HOROSCOPES_INDIAN_SOLAR_TAB = `${ALT_HOROSCOPES_INDIAN_SOLAR}_${POSTFIXES.TAB}`;
export const ALT_HOROSCOPES_INDIAN_SOLAR_SIGN = `${ALT_HOROSCOPES_INDIAN_SOLAR}_${POSTFIXES.SIGN}`;
export const ALT_HOROSCOPES_INDIAN_SOLAR_EXPAND = `${ALT_HOROSCOPES_INDIAN_SOLAR}_${POSTFIXES.EXPAND}`;

export const ALT_HOROSCOPES_DRUID = `${ALT_HOROSCOPES}_druid`;
export const ALT_HOROSCOPES_DRUID_OPEN = `${ALT_HOROSCOPES_DRUID}_${POSTFIXES.OPEN}`;
export const ALT_HOROSCOPES_DRUID_SIGN = `${ALT_HOROSCOPES_DRUID}_${POSTFIXES.SIGN}`;
export const ALT_HOROSCOPES_DRUID_EXPAND = `${ALT_HOROSCOPES_DRUID}_${POSTFIXES.EXPAND}`;

export const ALT_HOROSCOPES_MAYAN = `${ALT_HOROSCOPES}_mayan`;
export const ALT_HOROSCOPES_MAYAN_OPEN = `${ALT_HOROSCOPES_MAYAN}_${POSTFIXES.OPEN}`;
export const ALT_HOROSCOPES_MAYAN_SIGN = `${ALT_HOROSCOPES_MAYAN}_${POSTFIXES.SIGN}`;
export const ALT_HOROSCOPES_MAYAN_EXPAND = `${ALT_HOROSCOPES_MAYAN}_${POSTFIXES.EXPAND}`;

export const ALT_HOROSCOPES_CHINESE = `${ALT_HOROSCOPES}_chinese`;
export const ALT_HOROSCOPES_CHINESE_OPEN = `${ALT_HOROSCOPES_CHINESE}_${POSTFIXES.OPEN}`;
export const ALT_HOROSCOPES_CHINESE_SIGN = `${ALT_HOROSCOPES_CHINESE}_${POSTFIXES.SIGN}`;
export const ALT_HOROSCOPES_CHINESE_EXPAND = `${ALT_HOROSCOPES_CHINESE}_${POSTFIXES.EXPAND}`;
// [ALT_HOROSCOPES] END
// [DASHBOARD] END

// [PREDICTIONS] START
// [TAROT] START
export const TAROT = 'tarot';
export const TAROT_OPEN = `${TAROT}_${POSTFIXES.OPEN}`;
export const TAROT_EXPAND = `${TAROT}_${POSTFIXES.EXPAND}`;
export const TAROT_GET_RESULT = `${TAROT}_${POSTFIXES.GET_RESULT}`;
// [TAROT] END

// [MAGIC_BALL] START
export const MAGIC_BALL = 'magic_ball';
export const MAGIC_BALL_OPEN = `${MAGIC_BALL}_${POSTFIXES.OPEN}`;
export const MAGIC_BALL_GET_RESULT = `${MAGIC_BALL}_${POSTFIXES.GET_RESULT}`;
// [MAGIC_BALL] END
// [PREDICTIONS] END

// [SIGNS_COMPATIBILITY] START
export const SIGNS_COMPATIBILITY = 'sign_compatibility';
export const SIGNS_COMPATIBILITY_CHECK = `${SIGNS_COMPATIBILITY}_check`;
export const SIGNS_COMPATIBILITY_REPORT = `${SIGNS_COMPATIBILITY}_report`;
// [SIGNS_COMPATIBILITY] END

// [BEST_MATCHES] START
export const BEST_MATCHES = 'best_matches';
export const BEST_MATCHES_SIGN = `${BEST_MATCHES}_sing`;
export const BEST_MATCHES_ALL_SIGNS = `${BEST_MATCHES}_all_signs`;
export const BEST_MATCHES_OPEN = `${BEST_MATCHES_SIGN}_${POSTFIXES.OPEN}`;
export const BEST_MATCHES_ALL_SIGNS_OPEN = `${BEST_MATCHES_ALL_SIGNS}_${POSTFIXES.OPEN}`;
export const BEST_MATCHES_EXPAND = `${BEST_MATCHES_SIGN}_${POSTFIXES.EXPAND}`;
export const BEST_MATCHES_BANNER = `${BEST_MATCHES_SIGN}_${POSTFIXES.GUIDE_BANNER}`;
// [BEST_MATCHES] END

// [GUIDES] START
export const GUIDE = 'guide';
export const GUIDE_SESSION = `${GUIDE}_session`;
export const GUIDE_OPEN = `${GUIDE}_${POSTFIXES.OPEN}`;
export const GUIDE_SESSION_OPEN = `${GUIDE_SESSION}_${POSTFIXES.OPEN}`;
export const GUIDE_SESSION_START_NEXT = `${GUIDE_SESSION}_start_next`;
export const GUIDE_SESSION_BACK_TO_GUIDES = `${GUIDE_SESSION}_back_to_guides`;
export const GUIDE_SESSION_SKIP = `${GUIDE_SESSION}_skip`;
export const GUIDE_SESSION_SAVE_A_NOTE = `${GUIDE_SESSION}_save_a_note`;
export const GUIDE_SESSION_COMPLETE = `${GUIDE_SESSION}_complete`;
export const GUIDE_SESSION_UNLOCK_ALL = `${GUIDE_SESSION}_unlock_all`;
// [GUIDES] END

// [BIRTH_CHART] START
export const BIRTH_CHART = 'birth_chart';
export const BIRTH_CHART_YOUR_CHART = `${BIRTH_CHART}_your_chart`;
export const BIRTH_CHART_DAILY_TRANSITS = `${BIRTH_CHART}_daily_transits`;
export const BIRTH_CHART_OPEN = `${BIRTH_CHART}_${POSTFIXES.OPEN}`;
export const BIRTH_CHART_YOUR_CHART_OPEN = `${BIRTH_CHART_YOUR_CHART}_${POSTFIXES.OPEN}`;
export const BIRTH_CHART_DAILY_TRANSITS_OPEN = `${BIRTH_CHART_DAILY_TRANSITS}_${POSTFIXES.OPEN}`;
export const BIRTH_CHART_DAILY_TRANSITS_TAB = `${BIRTH_CHART_DAILY_TRANSITS}_${POSTFIXES.TAB}`;
// [BIRTH_CHART] END

export const PROMO_EXCLUDE_TRIGGERS = [PALM_READING_GET_RESULT];

export const PROMO_INCLUDE_TRIGGERS = [APP_FOREGROUND, PALM_READING_OPEN, PERSONAL_READING_OPEN];

// [ASTRO_CALENDAR] START
export const ASTRO_CALENDAR_BONUS = 'astro_calendar_bonus';
// [ASTRO_CALENDAR] END

export const BIRTH_CHARTS_COMPATIBILITY = 'birth_charts_compatibility';
export const BALANCE_REFILL_BEFORE_PACKS_NO_PURCHASED = 'balance_refill_before_packs_no_purch';
export const BALANCE_REFILL_BEFORE_PACKS_PURCHASED = 'balance_refill_before_packs_purch';
export const BALANCE_REFILL_AFTER_PACKS_NO_PURCHASED = 'balance_refill_after_packs_no_purch';
export const BALANCE_REFILL_AFTER_PACKS_PURCHASED = 'balance_refill_after_packs_purch';
export const REACTIVATION_EXCLUDE_TRIGGERS = [
  BALANCE_REFILL_BEFORE_PACKS_NO_PURCHASED,
  BALANCE_REFILL_BEFORE_PACKS_PURCHASED,
  BALANCE_REFILL_AFTER_PACKS_NO_PURCHASED,
  BALANCE_REFILL_AFTER_PACKS_PURCHASED,
];

// [ADVISORS]:
export const PREMIUM_ADVISOR_CLICK = 'premium_advisor_click';
export const PREMIUM_ADVISOR_START_CHAT = 'premium_advisor_start_chat';
export const ADVISOR_HOROSCOPE_SUBSCRIBE = 'advisor_horoscope_subscribe';

// [TRIGGERS WITHOUT SLIDE ANIMATION]:
export const TRIGGERS_WITHOUT_SLIDE_ANIM = [BIRTH_CHART_OPEN];
