export enum TYPES {
  SET_ACTIVE_MODAL = 'MODALS/SET_ACTIVE_MODAL',
  CLOSE_MODAL = 'MODALS/CLOSE_MODAL',
  SET_QUEUE = 'MODALS/SET_QUEUE',
}

export type Modal = {
  name: string;
  params: any;
};

interface State {
  activeModal: Modal['name'];
  params?: Modal['params'];
  queue: Modal[];
}

export const initialState: State = {
  activeModal: '',
  params: {},
  queue: [],
};

interface SetActiveModal {
  type: TYPES.SET_ACTIVE_MODAL;
  payload: {
    name: Modal['name'];
    params?: Modal['params'];
  };
}

interface SetCloseModal {
  type: TYPES.CLOSE_MODAL;
}

interface SetQueue {
  type: typeof TYPES.SET_QUEUE;
  payload: Modal[];
}

export type ActionTypes = SetActiveModal | SetQueue | SetCloseModal;
