import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import Text from 'components/text';
import { FONT_TYPES } from 'constants/fonts';

interface Props {
  children?: string;
  delimiter?: string | RegExp;
  style?: StyleProp<TextStyle>;
  highlightedTextStyle?: StyleProp<TextStyle>;
  font?: FONT_TYPES;
  numberOfLines?: number;
}

const HighLightText: React.FC<Props> = ({ children, style, highlightedTextStyle, font = 'OpenSans', delimiter = '**', numberOfLines }) => {
  if (typeof children !== 'string') {
    return null;
  }

  const textArr = children.split(delimiter);
  return (
    <Text font={font} style={style} numberOfLines={numberOfLines}>
      {textArr.map((part, i) => {
        if (i % 2 === 0) {
          return part;
        }
        return (
          <Text font={font} key={part} style={[{ fontWeight: 'bold' }, highlightedTextStyle]}>
            {part}
          </Text>
        );
      })}
    </Text>
  );
};

export default HighLightText;
