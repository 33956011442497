export const GRADIENT_DIRECTION = {
  START: { x: 0, y: 0.5 },
  END: { x: 0.5, y: 1 },
};

export type AdvisorAvatarSizes = 'small' | 'medium' | 'large';

export const sizes = {
  small: {
    width: 52,
    dotIndent: 6,
    premiumIconIndent: 0,
    nameIndent: 4,
  },
  medium: {
    width: 60,
    dotIndent: 6,
    premiumIconIndent: 0,
    nameIndent: 8,
  },
  large: {
    width: 72,
    dotIndent: 10,
    premiumIconIndent: 2,
    nameIndent: 8,
  },
};
