import { PDF_REPORTS } from 'constants/upsale';

import type { AstroCalendarConfig, AstroCalendarNotificationConfig } from '../interfaces';

import { ASTRO_CALENDAR_BONUS_TYPE, ASTRO_CALENDAR_GUIDE_SLUG } from './bonuses';
import { ASTRO_CALENDAR_NAVIGATE_TODO_ID, ASTRO_CALENDAR_TODO_TYPE } from './todos';

export * from './events';
export * from './todos';
export * from './bonuses';

export enum ASTRO_CALENDAR_SECTION_TYPE {
  ASTRO_EVENTS = 'astroEvents',
  TODOS = 'todos',
  BONUSES = 'bonuses',
}

export const ASTRO_CALENDAR_SECTIONS: ASTRO_CALENDAR_SECTION_TYPE[] = [
  ASTRO_CALENDAR_SECTION_TYPE.ASTRO_EVENTS,
  ASTRO_CALENDAR_SECTION_TYPE.TODOS,
  ASTRO_CALENDAR_SECTION_TYPE.BONUSES,
];

export const ASTRO_CALENDAR_PLACE = 'astro_calendar';

export enum ASTRO_CALENDAR_ORIGIN {
  NOTIFICATION = 'bonus_notification',
  ASTRO_CALENDAR_WIDGET = 'astro_calendar_widget',
  ASTRO_PLAN_WIDGET = 'astro_plan_widget',
  RETURN_BACK = 'return_back',
}

export const defaultAstroCalendarConfig: AstroCalendarConfig = {
  '1': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.ADVISORS,
      },
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.SIGN_COMPATIBILITY,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 1,
        code: 'bonus_1day_1min',
      },
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.HEALTHY_RELATIONSHIP,
      },
    ],
  },
  '2': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.PLANETS_AND_HOUSES,
      },
      {
        type: ASTRO_CALENDAR_TODO_TYPE.RATE_US,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.BIRTH_CHART,
      },
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 1,
        code: 'bonus_2day_1min',
      },
    ],
  },
  '3': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.PALMISTRY_ANALYSIS,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE,
        contentId: PDF_REPORTS.PALMISTRY_GUIDE,
      },
    ],
  },
  '4': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.ADVISORS,
      },
      {
        type: ASTRO_CALENDAR_TODO_TYPE.DAILY_FORECAST,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 5,
        code: 'bonus_4day_5min',
      },
    ],
  },
  '5': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TRANSITS,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.MARRIAGE_COMPATIBILITY,
      },
    ],
  },
  '6': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_READINGS,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE,
        contentId: PDF_REPORTS.NUMEROLOGY_ANALYSIS,
      },
    ],
  },
  '7': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.DATING_CALENDAR,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.BETTER_AT_DATING,
      },
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.FIND_TRUE_LOVE,
      },
    ],
  },
  '8': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.CHINESE_HOROSCOPE,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 5,
        code: 'bonus_8day_5min',
      },
    ],
  },
  '9': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TODAY_MATCHES,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.LONG_DISTANCE_RELATIONSHIP,
      },
    ],
  },
  '10': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_NEAR_FUTURE,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.PALM_READING,
      },
    ],
  },
  '11': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.BIORHYTHMS,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.BIORHYTHMS,
      },
    ],
  },
  '12': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.BEST_MATCHES,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.SIGNS_COMPATIBILITY,
      },
    ],
  },
  '13': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.LUNAR_CALENDAR,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.MANAGE_YOUR_MONEY,
      },
    ],
  },
  '14': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.INDIAN_LUNAR_HOROSCOPE,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.GUIDE,
        contentId: ASTRO_CALENDAR_GUIDE_SLUG.SUCCEED_AT_WORK,
      },
    ],
  },
  '15': {
    todos: [
      {
        type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
        contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_CARD_OF_THE_DAY,
      },
    ],
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 10,
        code: 'bonus_15day_10min',
      },
    ],
  },
  '18': {
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 10,
        code: 'bonus_18day_10min',
      },
    ],
    todos: [],
  },
  '24': {
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 10,
        code: 'bonus_24day_10min',
      },
    ],
    todos: [],
  },
  '30': {
    bonuses: [
      {
        type: ASTRO_CALENDAR_BONUS_TYPE.MINUTES,
        amount: 10,
        code: 'bonus_30day_10min',
      },
    ],
    todos: [],
  },
};

export const defaultAstroCalendarNotificationConfig: AstroCalendarNotificationConfig = {
  enabled: false,
  displayTimeout: 3,
  sessionStart: 1,
};
