import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import { getPrevCurrency } from 'store/billing/selectors';
import { getAstrologerServices } from 'api/astrologer-services';
import { AstrologyService } from 'api/astrologer-services/interfaces';

import { TYPES, Service } from './types';

const setServices = createAction(TYPES.SET_SERVICES);
export const setActiveServicesTab = createAction(TYPES.SET_ACTIVE_SERVICES_TAB);

export const initServices = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const isEnabled = getState().remoteConfig.advisorsServicesConfig?.enabled;

    if (isEnabled) {
      dispatch(fetchServices());
    }
  };
};

export const fetchServices = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const products = state.remoteConfig.advisorsServicesConfig?.products;

    try {
      const response = await getAstrologerServices();
      const currency = getPrevCurrency(state);

      const list = response.reduce((acc: Service[], item: AstrologyService) => {
        const prices = products?.[item.product_id];
        if (prices) {
          const preparePriceByCurrency = () => {
            if (!prices[currency]) {
              return {
                ...prices,
                ...item,
              };
            }

            return {
              ...item,
              ...prices[currency],
              currency,
            };
          };

          const preparedServiceProduct: Service = preparePriceByCurrency();

          acc.push(preparedServiceProduct);
        }

        return acc;
      }, []);

      dispatch(setServices(list));
      return response;
    } catch (error) {
      console.log('[ERROR GET ASTROLOGER SERVICES]', error);
      return [];
    }
  };
};
