import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import * as COLORS from 'constants/colors';

interface Props {
  isOnline: boolean;
  style?: StyleProp<ViewStyle>;
}

const ActiveStatus: FC<Props> = ({ isOnline, style = null }) => {
  return <View style={[styles.iconDot, style]}>{isOnline && <View style={styles.iconDotOnline} />}</View>;
};

const styles = StyleSheet.create({
  iconDot: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 14,
    height: 14,
    borderRadius: 100,
    bottom: 0,
    borderWidth: 2,
    borderColor: COLORS.DARK_GREY_BLUE,
    backgroundColor: COLORS.DARK_1,
  },
  iconDotOnline: {
    width: 8,
    height: 8,
    borderRadius: 100,
    backgroundColor: COLORS.AQUA,
  },
});

export default memo(ActiveStatus);
