export enum WIDGETS {
  SUMMARY = 'SUMMARY',
  DAILY_TIPS = 'DAILY-TIPS',
  DO_DONT = 'DO-DONT',
  BIORHYTHMS = 'BIORHYTHMS',
  MATCHES = 'MATCHES',
  FEATURES = 'FEATURES',
  ALTERNATIVE_HOROSCOPES = 'ALTERNATIVE-HOROSCOPES',
  MATCHES_OTHER_FEATURES = 'MATCHES-OTHER-FEATURES',
  DATING_CALENDAR = 'DATING-CALENDAR-WIDGET',
  LUNAR = 'LUNAR-CALENDAR',
  PALM_READING_DAILY = 'PALM-READING-DAILY',
  STORIES = 'STORIES',
  COMPATIBILITY = 'COMPATIBILITY',
  BIRTH_CHARTS_COMPATIBILITY = 'BIRTH-CHARTS-COMPATIBILITY',
  TAROT_CARD_OF_DAY = 'TAROT_CARD_OF_DAY',
  PALM_READING_REPORT = 'PALM-READING-REPORT',
  ASTROLOGIST = 'ASTROLOGIST',
  ZODIAC = 'ZODIAC',
  BEST_MATCHES = 'BEST_MATCHES',
  ASTRO_CALENDAR = 'ASTRO-CALENDAR',
  ASTRO_PLAN = 'ASTRO-PLAN',
}

export enum BANNERS {
  PALM_READING_BANNER = 'PALM_READING_BANNER',
  COMPATIBILITY_BANNER = 'COMPATIBILITY_BANNER',
  BIRTH_CHART_BANNER = 'BIRTH_CHART_BANNER',
  BIRTH_CHARTS_COMPATIBILITY_BANNER = 'BIRTH_CHARTS_COMPATIBILITY_BANNER',
  TAROT_BANNER = 'TAROT_BANNER',
}

export const FULL_WIDGETS_LIST = [
  WIDGETS.ASTROLOGIST,
  WIDGETS.BIRTH_CHARTS_COMPATIBILITY,
  WIDGETS.PALM_READING_REPORT,
  WIDGETS.SUMMARY,
  WIDGETS.STORIES,
  WIDGETS.PALM_READING_DAILY,
  WIDGETS.DO_DONT,
  WIDGETS.DAILY_TIPS,
  WIDGETS.MATCHES,
  WIDGETS.BIORHYTHMS,
  WIDGETS.LUNAR,
  WIDGETS.FEATURES,
  WIDGETS.DATING_CALENDAR,
  WIDGETS.ALTERNATIVE_HOROSCOPES,
  WIDGETS.TAROT_CARD_OF_DAY,
  WIDGETS.COMPATIBILITY,
  WIDGETS.ASTRO_CALENDAR,
  WIDGETS.ASTRO_PLAN,
  ...Object.values(BANNERS),
];

export const WEB_WIDGETS_LIST = [
  WIDGETS.STORIES,
  WIDGETS.SUMMARY,
  WIDGETS.DAILY_TIPS,
  WIDGETS.MATCHES,
  WIDGETS.FEATURES,
  WIDGETS.PALM_READING_REPORT,
  WIDGETS.COMPATIBILITY,
  BANNERS.BIRTH_CHART_BANNER,
  WIDGETS.TAROT_CARD_OF_DAY,
  BANNERS.TAROT_BANNER,
  WIDGETS.BIORHYTHMS,
  WIDGETS.LUNAR,
  WIDGETS.ALTERNATIVE_HOROSCOPES,
];

export const WIDGETS_WITH_NEW = [
  WIDGETS.SUMMARY,
  WIDGETS.PALM_READING_DAILY,
  WIDGETS.DAILY_TIPS,
  WIDGETS.MATCHES,
  WIDGETS.BIORHYTHMS,
  WIDGETS.LUNAR,
  WIDGETS.FEATURES,
  WIDGETS.DATING_CALENDAR,
  WIDGETS.ALTERNATIVE_HOROSCOPES,
  WIDGETS.ASTRO_CALENDAR,
  WIDGETS.ASTRO_PLAN,
  WIDGETS.BIRTH_CHARTS_COMPATIBILITY,
];

export enum READING_PAGE_WIDGETS {
  PALMISTRY = 'PALMISTRY',
  MAGIC_BALL = 'MAGIC_BALL',
  TAROT = 'TAROT',
  PALM_READING_REPORT = 'PALM-READING-REPORT',
}

export const COMPATIBILITY_WIDGETS_LIST = [WIDGETS.ZODIAC, WIDGETS.BEST_MATCHES, WIDGETS.MATCHES];
