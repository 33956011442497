import { createSelector } from 'reselect';

import { ONBOARDING_IDS, ONBOARDING_SCREENS, ONBOARDING_SUB_IDS } from 'constants/onboarding-types';
import { RootState } from 'store';

const getOnboardingFlow = (state: RootState) => state.remoteConfig.onboardingFlow;
const getOnboardingStep = (state: RootState) => state.onboarding.step;
const getOnboardingPassedSteps = (state: RootState) => state.onboarding.passedSteps as string[];
const getBillingPurchased = (state: RootState) => state.billing.purchased;

export const selectOnboardingSteps = createSelector(
  [getOnboardingFlow, getOnboardingStep, getOnboardingPassedSteps, getBillingPurchased],
  (onboardingFlow, step, passedSteps, purchased) => {
    let resultFlow = onboardingFlow ? [...onboardingFlow] : [];

    resultFlow = resultFlow
      .filter((x: string) => Object.keys(ONBOARDING_SCREENS).includes(x))
      .filter((x: string) => !passedSteps.includes(x) && x !== step);

    if (purchased) {
      resultFlow = resultFlow.filter((x: string) => !Object.keys(ONBOARDING_SUB_IDS).includes(x));
    }

    return resultFlow;
  },
);

const AVOID_DOTS_SCREENS = [ONBOARDING_IDS.WELCOME, ONBOARDING_IDS.PROCESSING];

export const selectOnboardingStepsWithDots = createSelector([getOnboardingFlow], onboardingFlow =>
  onboardingFlow
    ? onboardingFlow
        .filter((x: string) => Object.keys(ONBOARDING_SCREENS).includes(x))
        .filter((x: string) => !AVOID_DOTS_SCREENS.includes(x))
        .filter((x: string) => !Object.keys(ONBOARDING_SUB_IDS).includes(x))
    : [],
);
