import {
  WEEK,
  MONTH,
  YEAR,
  MONTH_C,
  MONTH_F,
  MONTH_D,
  YEAR_H,
  WEEK_A,
  WEEK_N,
  WEEK_H,
  WEEK_E,
  WEEK_L,
  YEAR_E,
  YEAR_F,
  YEAR_D,
  YEAR_C,
  FAMILY_WEEK,
  FAMILY_MONTH,
  FAMILY_YEAR,
  WEEK_F,
  LIFETIME,
  PRO_W,
} from './billing';

export enum PRICE_LOCATION {
  INSIDE = 'INSIDE',
  ABOVE = 'ABOVE',
  UNDER = 'UNDER',
  NONE = 'NONE',
}

export enum TEXTS_ALIGN {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export enum BADGE_ALIGN {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export const PRODUCTS = {
  WEEK,
  MONTH,
  YEAR,
  MONTH_C,
  MONTH_F,
  MONTH_D,
  WEEK_A,
  WEEK_N,
  WEEK_F,
  WEEK_E,
  WEEK_L,
  YEAR_H,
  WEEK_H,
  YEAR_E,
  YEAR_F,
  YEAR_D,
  YEAR_C,
  FAMILY_MONTH,
  FAMILY_WEEK,
  FAMILY_YEAR,
  LIFETIME,
  PRO_W,
};

export enum SUB_TYPES {
  VERTICAL = 'subVerticalProducts',
  FAMILY = 'subFamily',
  PALM = 'subPalm',
  POST = 'subPost',
  VERTICAL_ONE = 'subVerticalOneProduct',
  PROMO_OFFER = 'subPromoOffer',
  SUB_BENEFITS = 'subBenefits',
}

export enum SUB_FAMILY_TYPES {
  BASIC = 'basic',
  FAMILY = 'family',
}
