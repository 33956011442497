import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { initialState } from './types';
import type { Param, ParamNames } from './types';
import { defaultParams, ParamTypes } from './default-params';
import { getDefaultValue } from './utils';

const slice = createSlice({
  name: 'remoteConfig',
  initialState,
  reducers: {
    setRemoteConfig(state: { [key: string]: any }, action: PayloadAction<{ [key: string]: any }>) {
      defaultParams.forEach((param: Param<ParamNames>) => {
        const value = action.payload?.[param.name];

        if (value === undefined || value === null) {
          state[param.name] = getDefaultValue(param.default);
          return;
        }

        switch (param.type) {
          case ParamTypes.BOOLEAN: {
            state[param.name] = !!value;
            break;
          }
          case ParamTypes.NUMBER: {
            state[param.name] = isNaN(+value) ? getDefaultValue(param.default) : +value;
            break;
          }
          case ParamTypes.STRING: {
            state[param.name] = value;
            break;
          }
          case ParamTypes.JSON:
            try {
              state[param.name] = JSON.parse(value);
            } catch (err) {
              state[param.name] = getDefaultValue(param.default);
            }
            break;
        }
      });
    },
  },
});

export const { setRemoteConfig } = slice.actions;

export default persistReducer(
  {
    key: slice.name,
    storage: AsyncStorage,
  },
  slice.reducer,
);
