export const PRO_LABEL = 'pro';

export type ProLabelSizes = 'small' | 'large';

export const sizes = {
  small: {
    width: 23,
    height: 12,
    borderRadius: 4,
    textSize: 8,
    rightStar: 5.8,
    leftStar: 4.5,
    leftStarLeftIndent: -1.5,
    leftStarBottomIndent: -0.6,
    rightStarRightIndent: -2.4,
    rightStarTopIndent: -2.4,
  },
  large: {
    width: 50,
    height: 22,
    borderRadius: 5,
    textSize: 14,
    rightStar: 9,
    leftStar: 7,
    leftStarLeftIndent: -3,
    leftStarBottomIndent: -1,
    rightStarRightIndent: -3,
    rightStarTopIndent: -3,
  },
};
