import { FC, useEffect, useState } from 'react';

import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import { handleNotificationWebOpen, notificationsRequest } from 'store/notification/actions';
import { LOADING } from 'constants/routes';
import { NotificationType } from 'store/notification/types';
import { setDataFromPush } from 'store/notification/actions.web';

const NotificationHandler: FC = () => {
  const dispatch = useAppDispatch();

  const [notification, setNotification] = useState<NotificationType | null>(null);
  const currentRoute = useAppSelector(state => state.navigation.currentRoute);
  const authId = useAppSelector(state => state.auth.id);

  const isReady = currentRoute && currentRoute !== LOADING;

  useEffect(() => {
    if (authId) {
      dispatch(notificationsRequest());
    }
  }, [dispatch, authId]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event: MessageEvent<NotificationType>) => {
        dispatch(setDataFromPush(event.data));

        if (event.data.action === 'redirect-from-notificationclick') {
          Analytics.track('Push_Clicked', { isInitial: !!event.data?.isInitial });
          setNotification(event.data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isReady && notification?.astrologer_id) {
      dispatch(handleNotificationWebOpen(notification));
    }
  }, [dispatch, isReady, notification]);

  return null;
};

export default NotificationHandler;
