import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import { PRIORITIES, TIPS_IDS } from 'constants/tips-categories';
import { orderByPriority } from 'utils/array';
import { getDailyTips } from 'api/daily-tips';
import { ASTROLOGERS_PROMO_PLACES } from 'screens/advisors/constants';
import { PERIODS_IDS } from 'constants/periods';
import { MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD } from 'store/horoscope/constants';
import { selectUserHoroscopeV2 } from 'store/horoscope/selectors';
import Analytics from 'analytics';

import { TYPES } from './types';
import { Tip } from './types';

export const setTips = createAction(TYPES.SET_TIPS);

export const initAstrologersPromo = () => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const { remoteConfig } = getState();
    const isPromoEnabled = !!remoteConfig?.astrologersPromoConfig?.places?.[ASTROLOGERS_PROMO_PLACES.DAILY_TIPS];

    if (!isPromoEnabled) {
      return null;
    }

    return {
      id: 0,
      category: TIPS_IDS.DAILY_TIPS,
      text: '',
    };
  };
};

export const processDailyTipsPredictions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      remoteConfig: { summaryWidgetConfig, todayContentTipsEnabled },
    } = state;

    let dailyTipsResult: Tip[] = [];
    let contentVersion: 'v1' | 'v2' | 'error' = 'error';

    const period = PERIODS_IDS.TODAY;

    if (todayContentTipsEnabled && summaryWidgetConfig?.enableHoroscopesV2?.[MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD[period]]) {
      const horoscopeV2 = selectUserHoroscopeV2(state, period);

      if (horoscopeV2) {
        const tips = horoscopeV2.data.tips;

        const dailyTipsPredictions = Object.keys(tips)
          .map(key => {
            if (tips[key]) {
              return {
                category: key || '',
                text: tips[key] || '',
              };
            }
          })
          .filter((item): item is Tip => !!item);

        dailyTipsResult = dailyTipsPredictions;
        contentVersion = 'v2';
      }
    }

    if (!dailyTipsResult.length) {
      const dailyTips = await getDailyTips();

      if (dailyTips?.length > 0) {
        const tips = dailyTips.map(x => {
          return { id: x?.id || Math.random(), category: x?.category || '', text: x?.text || '' };
        });

        dailyTipsResult = tips;
        contentVersion = 'v1';
      }
    }

    const priorDailyTips: Tip[] = orderByPriority(dailyTipsResult, 'category', PRIORITIES);

    const astrologersPromo = dispatch(initAstrologersPromo());

    if (astrologersPromo) {
      priorDailyTips.push(astrologersPromo);
    }

    dispatch(setTips(priorDailyTips));

    Analytics.track('TodaysDailyTips_Content_Loaded', {
      version: contentVersion,
    });
  };
};
