const SUBSCRIPTION = 'sub';
const IN_APP = 'in_app';

export interface MonetizationConfig {
  [key: string]: Array<{ type: string; screen?: string }>;
}

export const MONETIZATION_TYPES = {
  SUBSCRIPTION,
  IN_APP,
};
