import { isStage } from 'constants/general';

const getAppUrl = (): string => {
  return isStage() ? 'https://astrology-stage.astroline.app' : 'https://astrology.astroline.app';
};

export const getImageWithUrl = (uri?: string) => {
  if (!uri) {
    return '';
  }
  const reg = /https:\/\/astrology-stage.astroline.app|https:\/\/astrology.astroline.app/i;

  const result = uri.match(reg);

  if (result === null) {
    return getAppUrl() + uri;
  }

  return uri;
};
