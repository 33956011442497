import { PERIOD_TYPES, Currency, OTHERS_PAYMENT_SCREENS } from 'modules/payments/constants';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import { AllPaymentMethods } from 'modules/payments/interfaces';
import { AstrologerUserData } from 'store/astrologers/monetization/types';

export enum PACK_SCREENS_IDS {
  PACKS = 'PACKS',
  WELCOME_OFFER = 'WELCOME_OFFER',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
  SPECIAL_OFFER_2 = 'SPECIAL_OFFER_2',
}

export enum PACK_TRIGGER_IDS {
  PACKS = 'asb_packs',
  WELCOME_OFFER = 'asb_packs_offer_welcome',
  OFFER_1 = 'asb_packs_offer1',
  OFFER_2 = 'asb_packs_offer2',
}

export interface AstrologerCreditsConfig {
  enabled: boolean;
  rate: number;
}

export interface AstrologerPacksFlows {
  noPurchased: PACK_SCREENS_IDS[];
  purchased: PACK_SCREENS_IDS[];
}

export type AstrologerPackRoutes = {
  [key in PACK_SCREENS_IDS]: string;
};

export enum ASTROLOGERS_TRIGGERS_TYPES {
  BALANCE = 'balance',
  CHAT_BTN = 'chat_btn',
  CHAT_TIME_UP = 'chat_time_up',
  CHAT_NOTIFICATION = 'chat_notification',
  END_CHAT = 'end_chat',
  ADVISORS_ONBOARDING = 'advisors_onboarding',
  BLUR_MESSAGE = 'blur_message',
  SUGGESTS = 'suggests',
  SUGGESTS_POPUP = 'suggests_popup',
  AUTO_REFILL = 'auto_refill',
  CONNECTION_OPEN = 'connection_open',
  TIP = 'tip',
}

export type AstrologersMonetizationTriggers = Exclude<
  ASTROLOGERS_TRIGGERS_TYPES,
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN
  | ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION
  | ASTROLOGERS_TRIGGERS_TYPES.ADVISORS_ONBOARDING
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS
  | ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP
>;

export type AstrologersMonetizationConfig = {
  [key in AstrologersMonetizationTriggers]?: boolean;
};

export interface CurrencyConfig {
  notActivePrice: string;
  activePrice: string;
  price: number;
}

export enum RightTextColors {
  GREEN = 'green',
}

export type RightTextColor = RightTextColors | string | null;

export interface Pack {
  title: string;
  minutesAmount: number;
  notActivePrice: string;
  activePrice: string;
  freeMinsAmount: number;
  saveAmount: string;
  price: number;
  badgeText?: string;
  rightText?: string;
  rightTextColor?: RightTextColor;
  rightSubText?: string;
  currency: Currency;
  USD?: CurrencyConfig;
  EUR?: CurrencyConfig;
  CAD?: CurrencyConfig;
  AUD?: CurrencyConfig;
}

export interface Product extends Pack {
  id: string;
  pricePerMin: string;
  credits: number;
  creditsRate?: number;
  pricePerCredit: string;
}

export enum PackType {
  ONE,
  TWO,
  THREE,
  FOUR,
}

export enum ViewType {
  FREE_MINS = 'freeMins',
  SAVE = 'save',
}

interface AstrologerConfigBase {
  title: string;
  subtitle: string;
  btnText: string;
  footnoteText: string;
}

export interface AstrologerPackSpecialOffer extends AstrologerConfigBase {
  pack: Pack;
}

export interface AstrologerPackWelcomeOffer extends AstrologerConfigBase {
  pack: Pack;
  percentsOff: number;
}

export interface AstrologerConsultationPacks extends AstrologerConfigBase {
  packType: PackType;
  withCloseIcon: boolean;
  defaultProductId: number;
  viewType: ViewType;
  packs: Pack[];
  percentsOff?: number;
}

export enum ASTROLOGER_CONSULTATION_PRODUCTS {
  ASTROLOGER_CONSULTATION_60 = 'astrologer_consultation_60',
  ASTROLOGER_CONSULTATION_600 = 'astrologer_consultation_600',
  ASTROLOGER_CONSULTATION_180 = 'astrologer_consultation_1800',
  ASTROLOGER_CONSULTATION_3600 = 'astrologer_consultation_3600',
}

export enum ASTROLOGER_TIP_PRODUCTS {
  ASTROLOGER_TIP_1 = 'astrologer_tip_1',
  ASTROLOGER_TIP_3 = 'astrologer_tip_3',
  ASTROLOGER_TIP_5 = 'astrologer_tip_5',
  ASTROLOGER_TIP_10 = 'astrologer_tip_10',
}

export const ASTROLOGER_PRODUCTS = {
  ...ASTROLOGER_CONSULTATION_PRODUCTS,
  ...ASTROLOGER_TIP_PRODUCTS,
} as const;

export interface Subs extends Product {
  id: string;
}

export interface PackProduct extends Pack {
  id: string;
  productId: Pack['minutesAmount'] | string;
  pricePerMin: string;
  credits: number;
  creditsRate?: number;
  pricePerCredit: string;
  type: PRODUCT_TYPES.ONE_TIME;
}

export interface SubsProduct extends Subs {
  price: number;
  currency: Currency;
  activePrice: string;
  productId: string;
  period: PERIOD_TYPES | string;
  type: PRODUCT_TYPES.SUBSCRIPTION;
}

export interface AstrologerPaymentProductActionEventParams {
  currency: Currency;
  amount: PackProduct['price'] | SubsProduct['price'];
  productCode: PackProduct['id'] | SubsProduct['id'];
  productType?: PRODUCT_TYPES;
}

export interface AstrologerPaymentErrorEventParams {
  message: string;
  code: string;
}

export interface AstrologerPaymentEventParams
  extends AstrologerUserData,
    Partial<AstrologerPaymentErrorEventParams>,
    Partial<AstrologerPaymentProductActionEventParams> {
  screen: PACK_SCREENS_IDS | OTHERS_PAYMENT_SCREENS.CHAT | OTHERS_PAYMENT_SCREENS.TIPS;
  packType?: PackType;
  method?: AllPaymentMethods;
  //for astrologersOnetimeMonetizationHandler only
  idfm?: string;
  source?: string;
  [key: string]: any;
}
