import React, { FC, memo, useMemo } from 'react';
import { StyleSheet, Image, TouchableOpacity, View } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';

import { t, t2 } from 'localization';
import * as COLORS from 'constants/colors';
import { PDF_REPORTS } from 'constants/upsale';

import Icon from '../../icon';
import Text from '../../text';
import { getPersonalReportsData } from '../const';
import { PersonalReport } from '../interfaces';

const GRADIENT_DIRECTION = {
  START: { x: 0, y: 0.5 },
  END: { x: 0.5, y: 1 },
};

interface Props {
  report: PDF_REPORTS;
  onPress: (item: PDF_REPORTS) => void;
  giftIcon?: boolean;
}

const PersonalReportItem: FC<Props> = ({ report, onPress, giftIcon }) => {
  const handleReportPress = (item: PDF_REPORTS) => {
    onPress(item);
  };

  const data: PersonalReport = useMemo(() => {
    return getPersonalReportsData()[report];
  }, [report]);

  return (
    <TouchableOpacity style={styles.root} onPress={() => handleReportPress(report)}>
      {giftIcon && (
        <LinearGradient
          style={styles.gift}
          start={GRADIENT_DIRECTION.START}
          end={GRADIENT_DIRECTION.END}
          locations={[0.3, 0.97]}
          colors={['#25E0CC', '#B861FF']}>
          <Text style={styles.giftTxt}>{t('MODALS.BIRTH_CHART_REPORT.GIFT')}</Text>
        </LinearGradient>
      )}

      <View style={styles.wrImage}>
        <Image source={data.image} style={styles.image} />
      </View>

      <Text font="Philosopher" style={styles.title}>
        {t2(`PERSONAL_REPORTS.${data.title}.TITLE`)}
      </Text>

      <Icon style={styles.iconArrow} name={'arrow'} size={sw(16)} color={COLORS.BEIGE} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: COLORS.DARK_GREY_BLUE,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: paddingVertical(5),
    width: '100%',
  },
  gift: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(12),
    paddingVertical: paddingVertical(1),
    borderRadius: 10,
    right: paddingHorizontal(15),
    top: paddingVertical(-10),
    height: sw(20),
    minWidth: sw(65),
  },
  giftTxt: {
    color: COLORS.WHITE,
    fontSize: fs(13),
    lineHeight: fs(18),
    fontWeight: '600',
    textAlign: 'center',
  },
  wrImage: {
    width: sw(90),
    height: sw(50),
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  title: {
    color: COLORS.BEIGE,
    fontSize: fs(16),
    fontWeight: '700',
    marginLeft: paddingHorizontal(-16),
  },
  iconArrow: {
    position: 'absolute',
    right: paddingHorizontal(10),
  },
});

export default memo(PersonalReportItem);
