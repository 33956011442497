import React, { FC, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fs } from '@wowmaking/ui/src/utils';
import { StyleSheet } from 'react-native';

import APP_ICON from 'assets/icons/app-icons/app-icon.png';
import { AppDispatch, RootState } from 'store';
import { removeTriggerNotifications, transferInApp } from 'store/astrologers/notifications/actions';
import { t } from 'localization';
import { DARK_TEAL } from 'constants/colors';
import HighLightText from 'components/hight-light-text';

import BaseTriggerNotification from './base';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from './index';

interface Props {}

const GoToMobileApp: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const appLink = useSelector((state: RootState) => state.remoteConfig.appLink);

  const handlePress = () => {
    dispatch(transferInApp(appLink));
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP]));
  };

  const handleClose = () => {
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP]));
  };

  return (
    <BaseTriggerNotification icon={APP_ICON} onPress={handlePress} onClose={handleClose} type={EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP}>
      <HighLightText style={styles.text}>{t('TRIGGER_NOTIFICATION.GO_TO_MOBILE_APP')}</HighLightText>
    </BaseTriggerNotification>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: fs(12),
    fontWeight: '600',
    color: DARK_TEAL,
    lineHeight: fs(20),
    flex: 1,
  },
});

export default memo(GoToMobileApp);
