import React, { FC, useState, useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import { useSelector } from 'react-redux';
import Animated, { LinearTransition } from 'react-native-reanimated';
import { fs, paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';

import { RootState } from 'store';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { t } from 'localization';

import Star from './components/star';
import iconStarActive from './images/active-star.png';
import iconStar from './images/inactive-star.png';
import terrible from './images/terrible.png';
import terribleActive from './images/terrible-active.png';
import bad from './images/bad.png';
import badActive from './images/bad-active.png';
import okay from './images/okay.png';
import okayActive from './images/okay-active.png';
import good from './images/good.png';
import goodActive from './images/good-active.png';
import great from './images/great.png';
import greatActive from './images/great-active.png';
import starsBg from './images/stars.png';

export const FACE_BUTTONS = [
  {
    icon: terrible,
    iconActive: terribleActive,
    title: 'RATE_US.RATING_TERRIBLE',
  },
  {
    title: 'RATE_US.RATING_BAD',
    icon: bad,
    iconActive: badActive,
  },
  {
    title: 'RATE_US.RATING_OKAY',
    icon: okay,
    iconActive: okayActive,
  },
  {
    title: 'RATE_US.RATING_GOOD',
    icon: good,
    iconActive: goodActive,
  },
  {
    title: 'RATE_US.RATING_GRATE',
    icon: great,
    iconActive: greatActive,
  },
];

interface Props {
  mode: 'modal' | 'banner';
  onRate: (rating: number) => void;
  onClose: () => void;
}

const RateUs: FC<Props> = ({ mode, onRate, onClose }) => {
  const [rated, setRated] = useState(false);
  const [rating, setRating] = useState(-1);
  const rateUsConfig = useSelector((state: RootState) => state.remoteConfig.rateUs);
  const isStars = rateUsConfig?.icons === 'stars';
  const title = rateUsConfig?.titleText || t('RATE_US.TITLE_2');
  const isModal = mode === 'modal';

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleRatePress = useCallback(
    (index: number) => {
      setRating(index);
      setTimeout(() => setRated(true), 500);
      onRate(index + 1);
    },
    [onRate],
  );

  const iconStyles = isStars ? styles.starIcon : styles.faceIcon;
  const contentStyles = isModal ? styles.modal : styles.banner;

  return (
    <Animated.View style={contentStyles} key={`content-${rated}`} layout={LinearTransition}>
      {!rated && (
        <View style={contentStyles}>
          <Text font="Philosopher" style={styles.title}>
            {title}
          </Text>
          <View style={styles.buttonContainer}>
            {FACE_BUTTONS.map((item, index) => {
              const isActive = isStars ? rating >= index : rating === index;
              const faceIcon = isActive ? item.iconActive : item.icon;
              const starIcon = isActive ? iconStarActive : iconStar;
              const icon = isStars ? starIcon : faceIcon;

              return <Star key={item.title} style={iconStyles} title={item.title} icon={icon} index={index} onPress={handleRatePress} />;
            })}
          </View>
          {!!isModal && (
            <TouchableOpacity onPress={handleClose}>
              <Text style={styles.noThx}>{t('RATE_US.NO_THX')}</Text>
            </TouchableOpacity>
          )}
        </View>
      )}

      {rated && (
        <View style={contentStyles}>
          <Image style={styles.starsBg} resizeMode="contain" source={starsBg} />
          <Text font="Philosopher" style={styles.title}>
            {t('ASSESSMENT.THANKS')}
          </Text>
          <Text style={styles.thankYouDesc}>{t('ASSESSMENT.DESCRIPTION')}</Text>
        </View>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: fs(26),
    lineHeight: fs(32),
    fontWeight: '700',
    textAlign: 'center',
    color: COLORS.GOLDEN,
  },
  thankYouDesc: {
    fontSize: fs(17),
    lineHeight: fs(25),
    marginTop: paddingVertical(20),
    fontWeight: '400',
    textAlign: 'center',
    color: COLORS.BEIGE,
  },
  starsBg: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  modal: {
    height: 300,
  },
  banner: {
    height: 200,
  },
  noThx: {
    fontSize: fs(14),
    lineHeight: fs(19),
    fontWeight: '400',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: COLORS.LIGHT_BEIGE,
  },
  buttonContainer: {
    marginVertical: paddingVertical(40),
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    paddingHorizontal: paddingHorizontal(25),
  },
  starIcon: {
    width: 40,
    height: 40,
  },
  faceIcon: {
    width: paddingHorizontal(45),
    height: paddingHorizontal(65),
  },
});

export default RateUs;
