export const ARIES = 'aries';
export const TAURUS = 'taurus';
export const GEMINI = 'gemini';
export const CANCER = 'cancer';
export const LEO = 'leo';
export const VIRGO = 'virgo';
export const LIBRA = 'libra';
export const SCORPIO = 'scorpio';
export const SAGITTARIUS = 'sagittarius';
export const CAPRICORN = 'capricorn';
export const AQUARIUS = 'aquarius';
export const PISCES = 'pisces';

export const ZODIAC_EMOJI_MAP: { [key: string]: string } = {
  [ARIES]: '\u2648',
  [TAURUS]: '\u2649',
  [GEMINI]: '\u264A',
  [CANCER]: '\u264B',
  [LEO]: '\u264C',
  [VIRGO]: '\u264D',
  [LIBRA]: '\u264E',
  [SCORPIO]: '\u264F',
  [SAGITTARIUS]: '\u2650',
  [CAPRICORN]: '\u2651',
  [AQUARIUS]: '\u2652',
  [PISCES]: '\u2653',
};

export const SIGN_CARDINAL = [ARIES, CANCER, LIBRA, CAPRICORN];
export const SIGN_FIXED = [TAURUS, LEO, SCORPIO, AQUARIUS];
export const SIGN_MUTABLE = [GEMINI, VIRGO, SAGITTARIUS, PISCES];

export const SIGNS_ZODIAC = [
  'ARIES',
  'TAURUS',
  'GEMINI',
  'CANCER',
  'LEO',
  'VIRGO',
  'LIBRA',
  'SCORPIO',
  'SAGITTARIUS',
  'CAPRICORN',
  'AQUARIUS',
  'PISCES',
];
