import { useEffect } from 'react';
import { every } from 'lodash';

import { PERIODS_IDS } from 'constants/periods';
import { useAppDispatch, useAppSelector } from 'store';
import { selectHasDataToGenerateUserHoroscopeV2 } from 'store/horoscope/selectors';
import { selectUserProfileIsLoaded } from 'store/profile/selectors';
import { selectActiveHoroscopeSubscription, selectAdvisorHoroscopeContentByPeriod } from 'store/astrologers/selectors';
import { loadAdvisorHoroscope, setNeedResetCache, updateHoroscopeIds } from 'store/astrologers/horoscope/actions';

const useLoadAdvisorHoroscopes = () => {
  const dispatch = useAppDispatch();

  const advisorHoroscopeEnabled = useAppSelector(state => state.remoteConfig.advisorHoroscopeEnabled);
  const systemLocale = useAppSelector(state => state.app.initialSystemLocale);
  const userProfileIsLoaded = useAppSelector(selectUserProfileIsLoaded);
  const hasDataToGenerate = useAppSelector(selectHasDataToGenerateUserHoroscopeV2);
  const horoscopeContent = useAppSelector(state => state.astrologers.horoscope.horoscopeContent);

  const todayHoroscope = useAppSelector(state => selectAdvisorHoroscopeContentByPeriod(state, PERIODS_IDS.TODAY));
  const weekHoroscope = useAppSelector(state => selectAdvisorHoroscopeContentByPeriod(state, PERIODS_IDS.WEEK));
  const monthHoroscope = useAppSelector(state => selectAdvisorHoroscopeContentByPeriod(state, PERIODS_IDS.MONTH));
  const yearHoroscope = useAppSelector(state => selectAdvisorHoroscopeContentByPeriod(state, PERIODS_IDS.YEAR));

  const dataIsUpdated = useAppSelector(state => state.astrologers.horoscope.dataIsUpdated);
  const horoscopeSubscriptionIsActive = useAppSelector(selectActiveHoroscopeSubscription);

  const appIsReady = userProfileIsLoaded && !!systemLocale;
  const horoscopesCanBeLoaded = appIsReady && hasDataToGenerate && advisorHoroscopeEnabled && dataIsUpdated && horoscopeSubscriptionIsActive;
  const fullHoroscopeContentIsLoaded = every(Object.values(horoscopeContent), content => !!content);

  useEffect(() => {
    if (horoscopesCanBeLoaded) {
      if (!todayHoroscope) {
        dispatch(loadAdvisorHoroscope(PERIODS_IDS.TODAY));
      }

      if (todayHoroscope && !weekHoroscope) {
        dispatch(loadAdvisorHoroscope(PERIODS_IDS.WEEK));
      }

      if (weekHoroscope && !monthHoroscope) {
        dispatch(loadAdvisorHoroscope(PERIODS_IDS.MONTH));
      }

      if (monthHoroscope && !yearHoroscope) {
        dispatch(loadAdvisorHoroscope(PERIODS_IDS.YEAR));
      }
    }
  }, [dispatch, horoscopesCanBeLoaded, monthHoroscope, todayHoroscope, weekHoroscope, yearHoroscope]);

  useEffect(() => {
    if (fullHoroscopeContentIsLoaded) {
      dispatch(updateHoroscopeIds());
      dispatch(setNeedResetCache(false));
    }
  }, [dispatch, fullHoroscopeContentIsLoaded]);
};

export default useLoadAdvisorHoroscopes;
