import { PROFILE_TYPES } from 'store/profile/types';
import { ADVISORS_CATEGORIES } from 'screens/advisors/screens/catalog/types';

import { TYPES, ActionType, State } from './types';

const initialState: State = {
  categories: {
    [ADVISORS_CATEGORIES.LOVE]: [],
    [ADVISORS_CATEGORIES.RECOMMENDED]: [],
    [ADVISORS_CATEGORIES.ACCURATE]: [],
    [ADVISORS_CATEGORIES.PREMIUM]: [],
  },
  previousDateForThreeDays: null,
  previousDateForSevenDays: null,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.payload,
        },
      };
    case TYPES.SET_PREVIOUS_DATE_FOR_THREE_DAYS:
      return {
        ...state,
        previousDateForThreeDays: action.payload,
      };
    case TYPES.SET_PREVIOUS_DATE_FOR_SEVEN_DAYS:
      return {
        ...state,
        previousDateForSevenDays: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
