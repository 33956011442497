import React, { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Analytics from 'analytics';
import { t, t2 } from 'localization';
import { ASTROLOGERS_PROMO_PLACES } from 'screens/advisors/constants';
import { AppDispatch, RootState } from 'store';
import { startAstrologersPromo } from 'store/astrologers/chat/actions';

import ASTROLOGERS_PROMO_COMPONENTS_MAP, { ASTROLOGERS_PROMO_SIZES } from './constants';

interface Props {
  type: Exclude<ASTROLOGERS_PROMO_SIZES, ASTROLOGERS_PROMO_SIZES.DEFAULT>;
  place: ASTROLOGERS_PROMO_PLACES;
  style?: StyleProp<ViewStyle>;
  onPress?: () => {
    message: string;
    tarotCard?: string;
  };
}

const AstrologersPromo: FC<Props> = ({ type, place, onPress, style }) => {
  const dispatch = useDispatch<AppDispatch>();
  const config = useSelector((state: RootState) => state.remoteConfig.astrologersPromoConfig || {});
  const isEnabled = config?.places?.[place] || false;

  const buttonTitle = config?.buttonTitle?.length ? config?.buttonTitle : t('ASTROLOGER_PROMO.BUTTON_TITLE');
  const title = t2(`ASTROLOGER_PROMO.${place.toUpperCase()}.TITLE`);
  const text = t2(`ASTROLOGER_PROMO.${place.toUpperCase()}.TEXT`);
  const Components = ASTROLOGERS_PROMO_COMPONENTS_MAP?.[type] || ASTROLOGERS_PROMO_COMPONENTS_MAP.default;

  const handlePress = () => {
    Analytics.track('Astrologers_Promo_Click', { place });
    let additionalData: {
      message: string;
      tarotCard?: string;
    } = { message: '' };

    if (onPress && typeof onPress === 'function') {
      additionalData = onPress();
    }

    dispatch(startAstrologersPromo(place, additionalData));
  };

  if (!isEnabled) {
    return null;
  }

  return <Components buttonTitle={buttonTitle} title={title} subTitle={text} onPress={handlePress} style={style} />;
};

export default AstrologersPromo;
