import _ from 'lodash';
import { createAction } from 'redux-actions';

import { AppGetState, AppDispatch } from 'store';
import { completeSession, getGuides, getGuideSession, rankSession, sendFeedbackForSession, setMedalForSession } from 'api/guides';
import { Course, Session, CourseFormats, Answer } from 'api/guides/interfaces';
import { sortRandom, sortRandomForDay } from 'utils/seedrandom';
import Analytics from 'analytics';

import { TYPES } from './types';

const FOLLOWERS_COUNT = 45;

export const setGuides = createAction(TYPES.SET);
export const setSurveyPassed = createAction(TYPES.SURVEY_PASSED);
export const clearSessions = createAction(TYPES.CLEAR_SESSIONS);
export const setMedal = createAction(TYPES.SET_MEDAL);
const sessionsLoading = createAction(TYPES.SESSIONS_LOADING);
const setFollowersData = createAction(TYPES.SET_FOLLOWERS);
const setSessions = createAction(TYPES.SET_SESSIONS);
export const saveAnswers = createAction(TYPES.SAVE_ANSWERS, (sessionId: Session['id'], answers: Answer[]) => ({
  sessionId,
  answers,
}));
export const setAudioLevel = createAction(TYPES.SET_AUDIO_LEVEL);

export const initGuides = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const state = getState();

      const guides = await getGuides();
      const { randomGuidesSequence } = state.remoteConfig;
      let seed = state.guides.seed;

      if (!seed) {
        seed = _.random(100000, false);
      }
      const guidesList = randomGuidesSequence ? sortRandom(guides, seed) : guides;

      return dispatch(setGuides({ guides: guidesList, seed }));
    } catch (error) {
      console.log('[ERROR GET GUIDES]: ', error);
    }
  };
};

export const initFollowersData = () => {
  return (dispatch: AppDispatch) => {
    const icons = [...Array(FOLLOWERS_COUNT).keys()];
    const followersIcons = sortRandomForDay(icons).array;

    return dispatch(setFollowersData(followersIcons));
  };
};

export const initSession = (id: Course['id']) => {
  return async (dispatch: AppDispatch) => {
    dispatch(clearSessions());
    dispatch(sessionsLoading());
    try {
      const sessions = await getGuideSession(id);
      return dispatch(setSessions(sessions));
    } catch (error) {
      console.log('[ERROR GET SESSIONS]: ', error);
    }
  };
};

export const finishSession = (sessionId: Session['id']) => {
  return async (dispatch: AppDispatch) => {
    try {
      const session = await completeSession(sessionId);
      dispatch(updateSessions(session));
      return dispatch(initGuides());
    } catch (error) {
      console.log('[ERROR FINISH SESSION]: ', error);
    }
  };
};

export const rateSession = (sessionId: Session['id'], rate: number) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    return rankSession(sessionId, rate).then(sessionFormat => {
      dispatch(updateSessions(sessionFormat));
      const state = getState();
      const guideName = state.guides.guides.find(x => x.id.toString() === sessionFormat.guide_id.toString())?.short_name;
      Analytics.trackEvent('Guides_RateSession', 'Click', {
        Guide: guideName,
        Session: sessionFormat.position,
        SessionFormat: sessionFormat.format,
        Stars: rate,
      });
    });
  };
};

export const feedbackForSession = (sessionId: Session['id'], emotions: string[], preferable_format: CourseFormats, review: string) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    return sendFeedbackForSession(sessionId, emotions, preferable_format, review).then(sessionFormat => {
      dispatch(updateSessions(sessionFormat));
      const state = getState();
      const guideName = state.guides.guides.find(x => x.id.toString() === sessionFormat.guide_id.toString())?.short_name;
      Analytics.trackEvent('Guides_JournalLessonFormat', 'Choose', {
        Guide: guideName,
        Session: sessionFormat.position,
        LessonFormat: preferable_format,
      });
    });
  };
};

export const sendMedal = (sessionId: Session['id']) => {
  return (dispatch: AppDispatch) => {
    return setMedalForSession(sessionId).then(sessions => {
      dispatch(updateSessions(sessions));
      dispatch(setMedal());
    });
  };
};

export const updateSessions = (newSessionFormat: Session) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { sessions } = state.guides;
    const newSessions = sessions.map(session => {
      if (session.position === newSessionFormat?.position) {
        const newSession = { ...session };
        newSession[newSessionFormat.format] = newSessionFormat;
        newSession.is_completed = newSessionFormat.is_completed;
        newSession.completed_date = newSessionFormat.completed_date;

        return newSession;
      }

      return session;
    });

    dispatch(setSessions(newSessions));
  };
};
