import { Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';

export const isStage = (): boolean => {
  return DeviceProps.getAppVersion().indexOf('-') !== -1;
};

export const getMerchantId = (): string => {
  return Platform.select({
    ios: 'merchant.today.astroline.sub',
    android: isStage() ? 'api_pk_01b785750b7946b5a594a7f215fc44e4' : 'api_pk_56b64b84adec481aa2ec4ca419a52dc1',
    default: '',
  });
};

export const getWebSubCancelLink = (): string => {
  const webLinkDevDefiner = isStage() ? '-stage' : '';
  const webAppUrl = Platform.OS === 'web' ? process.env.REACT_APP_WEB_SUB_URL : `https://advisors${webLinkDevDefiner}.astroline.today`;
  return `${webAppUrl}/terminate?click_source=app&email=`;
};

export const APP_NAME = Platform.select({
  ios: 'Atrix',
  android: 'Atrix',
  web: process.env.REACT_APP_TITLE,
});

export const BUNDLE_ID = 'com.atrixapp';

export const IOS_APP_ID = '6737768525';

export const MAGNUS_TOKEN = Platform.select({
  android: 'RYRm15zig6NE5JgiNV7OmgmJBKXBAvPO',
  ios: 'IxhWrG0qRkhL6TYbxQo9592oDcX8G1zs',
  web: process.env.REACT_APP_MAGNUS_TOKEN,
  default: '',
});

export const FACEBOOK_APP_ID = '901682178581919';

export const AMPLITUDE_KEY = Platform.select({
  android: '5ba43c9fac3d82de23dc89d79114bb3e',
  ios: 'f3affa2e0e86b18d28d791c395d2b711',
  default: '',
});

export const GOOGLE_CLOUD_API_KEY = Platform.select({
  android: 'AIzaSyAFkG9VjObcDheHl_7ToMBz-0A3ENo42UE',
  ios: 'AIzaSyBUsLvdrbGeXYZRysM_AAlLK-c0R4-oVts',
});

export const TITLE = Platform.select({
  android: 'Atrix',
  ios: 'Atrix',
  web: process.env.REACT_APP_TITLE,
});

export const SUB_CANCEL_LINK = Platform.select({
  ios: 'itms-apps://apps.apple.com/account/subscriptions',
  android: 'https://play.google.com/store/account/subscriptions',
});

export const ASB_URL = process.env.REACT_APP_WEB_ASB_URL;

export const SUPPORT_TOPIC = Platform.select({
  android: '[Atrix]',
  ios: '[Atrix]',
  web: `[${process.env.REACT_APP_TITLE}]`,
});

export const ZENDESK_TOKEN =
  Platform.OS === 'web' ? process.env.REACT_APP_ZENDESK_TOKEN : '74f1278a060753c72a41192ad686820c7199cea526dd8881965d72eb02a274bb';

export const HELPDESK_TEAM_ID = Platform.select({
  web: 'df0715f5-1dfa-4575-a6a4-bfa9f084eb25',
  ios: '9ca18b09-b125-4a50-a9c1-a54d191cdf6b', // Astroline
  android: 'c45e666a-cbaa-4484-85c2-5833370ca286',
}) as string;

export const HELPDESK_REQUIRED_SUBSCRIPTIONS_TAGS = {
  app: '0ba6e81b-f106-4333-921e-de1b657b81e3',
  web: '06d2241a-97a8-4e16-9dc5-245d72bc1488',
} as any;

export const SUPPORT_EMAIL = Platform.select({
  ios: 'astroline.ios@appdesk.zendesk.com',
  android: 'astroline.android@appdesk.zendesk.com',
  web: process.env.REACT_APP_SUPPORT_EMAIL,
});

export const STORE_LINK = Platform.select({
  ios: `itms-apps://apps.apple.com/app/id${IOS_APP_ID}?action=write-review`,
  android: `https://play.google.com/store/apps/details?id=${BUNDLE_ID}`,
});

export const ADJUST_APP_TOKEN = 'ik5c1e7wza4g';

export const ADJUST_UNIVERSAL_LINK = 'f6sw.adj.st';

export const ADJUST_EVENTS = {
  view_product: 'b36u4o',
  subscription_success: '4bhk2p',
  app_activity: 'lqjoci',
};

export const DO_NOT_REMEMBER = 2400;

export const ADJUST_OFFER_QUERY_PARAM = 'offer';

export const TRUST_REDIRECT_URI = '/review-success.html';

// TODO: шо здесь?
export const TRUST_PILOT_URL = 'https://www.trustpilot.com/evaluate/astroline.today';
