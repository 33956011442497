import EventEmitter from 'eventemitter3';

/* Events and Params */

interface EventParams {
  sub_close_after_packs: undefined;
  sub_close_before_packs: undefined;
}

/* Emitter */

type GetEventTypes<P extends object> = {
  [E in keyof P]: P[E] extends undefined ? () => void : (params: P[E]) => void;
};

type EventTypes = GetEventTypes<EventParams>;

export const ee = new EventEmitter<EventTypes>();

/* Utils */

export const createEventAwaiter = <E extends EventEmitter.EventNames<EventTypes>>(event: E): Promise<EventEmitter.EventArgs<EventTypes, E>[0]> => {
  return new Promise(res => {
    ee.once(event, (...args) => {
      res(args[0]);
    });
  });
};
