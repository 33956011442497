import { isStage } from 'constants/general';
import { PDF_REPORTS } from 'constants/upsale';

import BIRTH_CHART from './img/birth-chart.png';
import COMPATIBILITY from './img/compatibility.png';
import { PersonalReports } from './interfaces';

export const getPersonalReportsData = (): PersonalReports => {
  return {
    [PDF_REPORTS.BIRTH_CHART]: {
      title: 'BIRTH_CHART',
      image: BIRTH_CHART,
      pathName: isStage() ? `birth-chart-stage` : `birth-chart`,
    },
    [PDF_REPORTS.COMPATIBILITY]: {
      title: 'COMPATIBILITY',
      image: COMPATIBILITY,
      pathName: 'compatibility',
    },
  };
};
