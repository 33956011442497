import { createSelector } from 'reselect';
import moment from 'moment';
import _ from 'lodash';
import { Product } from '@wowmaking/react-native-billing';

import { RootState } from 'store';
import { WebOneTimePurchases, WebPurchases, WebPurchase, PaymentMethods, WebProduct, WebOneTimePurchase } from 'api/purchases/interfaces';
import { ONE_TIME_PRODUCTS } from 'modules/payments/constants/product';
import { WEB_PAYMENT_SERVICES, AVAILABLE_ONECLICK_METHODS, Currency, WEB_SUBSCRIPTION_STATUS } from 'modules/payments/constants';

const getWebAllSubscriptions = (state: RootState) => state.billing.webAllSubscriptions;
const getWebOneTimePurchases = (state: RootState) => state.billing.webOneTimePurchases;
const getWebPaymentMethod = (state: RootState) => state.billing.webPaymentMethod;
const getOneClickEnableParam = (state: RootState) => state.remoteConfig.isOneClickEnabled;
const getWebProducts = (state: RootState) => state.billing.webProducts;
const getProducts = (state: RootState) => state.billing.products;

export const getCurrentWebPaymentServices = createSelector([getWebAllSubscriptions], (transactions: WebPurchases) => {
  return transactions.reduce((services: WEB_PAYMENT_SERVICES[], tr: any) => {
    if (tr.payment_service) {
      services.push(tr.payment_service);
    }
    return services;
  }, []);
});

export const getWebOneTimePurchaseProducts = createSelector(
  [getWebOneTimePurchases],
  (oneTimePurchases: WebOneTimePurchase[]): ONE_TIME_PRODUCTS[] => {
    return oneTimePurchases.map(purchase => purchase.product_code as unknown as ONE_TIME_PRODUCTS);
  },
);

export const getWebActiveSubscription = createSelector([getWebAllSubscriptions], (subscriptions: WebPurchase[]): WebPurchase | undefined => {
  return subscriptions.find(i => i?.active && !i?.canceled_at);
});

export const isSolidgatePaymentService = createSelector([getCurrentWebPaymentServices], paymentServices =>
  paymentServices.some((ps: WEB_PAYMENT_SERVICES) => [WEB_PAYMENT_SERVICES.SOLIDGATE].includes(ps)),
);

export const getEmailFromSubscriptions = createSelector([getWebAllSubscriptions], (subscriptions: WebPurchases) => {
  let currentSub = subscriptions.find(i => i!.active && !i!.canceled_at);

  if (_.isEmpty(currentSub)) {
    currentSub = subscriptions.sort((a, b) => moment(b?.expire_at).valueOf() - moment(a?.expire_at).valueOf())[0];
  }

  return currentSub?.email;
});

export const getPrevCurrency = createSelector(
  [getWebAllSubscriptions, getWebOneTimePurchases],
  (transactions: WebPurchases, oneTimePurchases: WebOneTimePurchases): Currency => {
    const currency = transactions?.[0]?.currency || oneTimePurchases?.[0]?.currency;

    return currency;
  },
);

export const getPrevProject = createSelector([getWebOneTimePurchases], (oneTimePurchases: WebOneTimePurchases) => {
  return oneTimePurchases?.[0]?.project;
});

export const checkOneClickAvailable = createSelector(
  [getWebPaymentMethod, getOneClickEnableParam],
  (paymentMethod: PaymentMethods | '', isEnabled: boolean) => {
    return isEnabled && paymentMethod && AVAILABLE_ONECLICK_METHODS.includes(paymentMethod);
  },
);

export const getWebProduct = createSelector([getWebProducts, (_state, id: string) => id], (webProducts, id): WebProduct | null => {
  return webProducts.find(p => p.id === id) || null;
});

export const getProduct = createSelector([getProducts, (_state, id: string) => id], (products, id): Product | null => {
  return products.find(p => p.productId === id) || null;
});

export const checkIsReactivate = createSelector(
  [getWebActiveSubscription, getWebAllSubscriptions],
  (activeSubscription, webAllSubscriptions): boolean => {
    const isReactivate = webAllSubscriptions.find(sub => [WEB_SUBSCRIPTION_STATUS.CANCELED, WEB_SUBSCRIPTION_STATUS.EXPIRED].includes(sub?.status));
    return !activeSubscription && !!isReactivate;
  },
);
