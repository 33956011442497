import api from 'api';

import { Astrologist, START_CHAT, ChatPlatforms } from './constants';

const startChat = async ({
  id,
  platform,
  message = '',
  needResponse = 1,
  metadata,
  webProject = 'undetected',
  version = 'undetected',
}: {
  id: Astrologist['astrologer_id'];
  platform: ChatPlatforms;
  message: string;
  needResponse?: number;
  metadata: any;
  webProject: string;
  version: string;
}): Promise<{ chat_id: number; error?: string } | undefined> => {
  try {
    const response = await api.fetch(START_CHAT, {
      method: 'POST',
      data: {
        astrologer_id: id,
        message,
        needResponse,
        platform,
        metadata,
        webProject,
        version,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] startChat', error);
    return {
      chat_id: 0,
    };
  }
};

export default startChat;
