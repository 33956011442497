import { createAction } from 'redux-actions';
import queryString from 'query-string';
import DeviceProps from '@magnus/react-native-device-props';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { addNotificationToQueue, removeNotificationFromQueue } from 'store/notification-center/actions';
import { NOTIFICATIONS } from 'constants/notification-center';
import { getAccount } from 'api/whatsapp';
import { t } from 'localization';
import { showModal } from 'store/modals/actions';
import { MESSENGERS } from 'constants/modals';
import { subscribeOnEmailNewsletter } from 'api/notifications';

import { TYPES } from './types';

const setBotId = createAction(TYPES.SET_BOT_ID);
const setWhatsAppNotificationPressed = createAction(TYPES.SET_WHATS_APP_NOTIFICATION_PRESS);
const setWhatsAppNotificationSkipCounter = createAction(TYPES.SET_WHATS_APP_NOTIFICATION_SKIP_COUNTER);
const setMessengersNotificationPressed = createAction(TYPES.SET_MESSENGERS_NOTIFICATION_PRESS);
const setMessengersNotificationSkipCounter = createAction(TYPES.SET_MESSENGERS_NOTIFICATION_SKIP_COUNTER);

export const initMessengers = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const whatsAppData = await getAccount();

      if (!whatsAppData?.account) {
        return dispatch(setBotId(''));
      }

      dispatch(setBotId(`${whatsAppData.account}`));

      dispatch(showWhatsAppNotification());
      dispatch(showMessengersNotification());
    } catch (error) {
      console.log('ERROR getAccount', error);
    }
  };
};

export const showMessengersNotification = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const purchased = state.billing.purchased;
    const messengersConfig = state.remoteConfig.notificationCenter?.messengers || {};
    const messengersModalConfig = state.remoteConfig.messengersModalConfig || {};
    const { messengersNotificationSkipCounter, isMessengersNotificationPressed, botId } = state.messengers;

    const maxShowCount = messengersConfig?.maxShowCount || 3;
    const showSessionNumber = messengersConfig?.sessionNumber || 2;
    const currentSessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;
    const modalButtons = messengersModalConfig?.buttons || {};

    if (!purchased || isMessengersNotificationPressed || !messengersConfig?.enabled || !botId?.length) {
      return false;
    }

    if (!Object.keys(modalButtons)?.length) {
      return false;
    }

    if (currentSessionNumber < showSessionNumber) {
      return false;
    }

    if (messengersNotificationSkipCounter >= maxShowCount) {
      return false;
    }

    dispatch(setMessengersNotificationSkipCounter(messengersNotificationSkipCounter + 1));
    dispatch(addNotificationToQueue(NOTIFICATIONS.MESSENGERS));

    return true;
  };
};

export const startMessengersNotificationFlow = () => {
  return (dispatch: AppDispatch) => {
    dispatch(removeNotificationFromQueue(NOTIFICATIONS.MESSENGERS));
    dispatch(setMessengersNotificationPressed());
    dispatch(setMessengersNotificationSkipCounter(0));
    dispatch(showModal(MESSENGERS));
  };
};

export const showWhatsAppNotification = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const purchased = state.billing.purchased;
    const whatsappConfig = state.remoteConfig.notificationCenter?.whatsapp || {};
    const { whatsAppNotificationSkipCounter, isWhatsAppNotificationPressed, botId } = state.messengers;

    const maxShowCount = whatsappConfig?.maxShowCount || 3;
    const showSessionNumber = whatsappConfig?.sessionNumber || 2;
    const currentSessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;

    if (!purchased || isWhatsAppNotificationPressed || !whatsappConfig?.enabled || !botId?.length) {
      return false;
    }

    if (currentSessionNumber < showSessionNumber) {
      return false;
    }

    if (whatsAppNotificationSkipCounter >= maxShowCount) {
      return false;
    }

    dispatch(setWhatsAppNotificationSkipCounter(whatsAppNotificationSkipCounter + 1));
    dispatch(addNotificationToQueue(NOTIFICATIONS.WHATS_APP));

    return true;
  };
};

export const startWhatsAppBot = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      messengers: { botId },
    } = getState();

    const idfm = await DeviceProps.getIDFM();
    const text = t('NOTIFICATION_CENTER.WHATS_APP.MESSAGE', { idfm });
    const query = queryString.stringify({
      text,
    });
    const link = `https://wa.me/${botId}?${query}`;

    dispatch(setWhatsAppNotificationSkipCounter(0));
    dispatch(setWhatsAppNotificationPressed());
    dispatch(removeNotificationFromQueue(NOTIFICATIONS.WHATS_APP));

    window.open(link, '_blank');
  };
};

export const startEmailNewsletter = () => {
  return async () => {
    try {
      await subscribeOnEmailNewsletter({ email_subscribe: 1 });
    } catch (error) {
      console.warn('ERROR startEmailNewsletter', error);
    }
  };
};

export const showMessengersModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const messengersModalConfig = state.remoteConfig.messengersModalConfig || {};
    const modalButtons = messengersModalConfig?.buttons || {};

    if (!messengersModalConfig?.enabled) {
      return false;
    }

    if (Object.values(modalButtons).every(item => !item)) {
      return false;
    }

    dispatch(showModal(MESSENGERS));
    return true;
  };
};
