import { Linking, Platform } from 'react-native';
import { createAction } from 'redux-actions';
import moment from 'moment';
import DeviceProps from '@magnus/react-native-device-props';
import _ from 'lodash';

import Analytics from 'analytics';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { addNotificationToQueue } from 'store/notification-center/actions';
import { showModal } from 'store/modals/actions';
import { navigate } from 'store/navigation/actions';
import * as MODALS from 'constants/modals';
import { NOTIFICATIONS } from 'constants/notification-center';
import { selectZodiacSign } from 'store/profile/selectors';
import * as ROUTES from 'constants/routes';
import { prepareReportDownloadLink } from 'utils/upsale';
import { getPersonalReportsData } from 'components/personal-reports-banner/const';
import { PersonalReport } from 'components/personal-reports-banner/interfaces';
import { PDF_REPORTS } from 'constants/upsale';
import { REPORTS_PRODUCTS } from 'modules/payments/constants/product';

import { getAvailablePDFReports } from './selectors';
import { SET_VIEWED, OpenReportPlace } from './types';

const isWeb = Platform.OS === 'web';

export const setViewed = createAction(SET_VIEWED);

export const checkBirthChartAvailableForInApp = (): AppThunk<boolean> => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { webTransactions, webOneTimePurchases },
      remoteConfig: { birthChartAvailableForInAppFromDate: fromDate },
    } = getState();

    const isOldSubscriber = !!webTransactions.find((tr: any) => moment(fromDate).isAfter(tr.created_at, 'day'));

    const purchasedBirthChart = !!webOneTimePurchases.find((p: any) =>
      [REPORTS_PRODUCTS.BIRTH_CHART, REPORTS_PRODUCTS.ULTRA_2].includes(p.product_code),
    );

    return purchasedBirthChart || isOldSubscriber;
  };
};

export const unlockBirthChart = (onSuccess: () => void): AppThunk => {
  return (dispatch: AppDispatch) => {
    const isAvailable = dispatch(checkBirthChartAvailableForInApp());

    if (isAvailable) {
      return onSuccess();
    }

    dispatch(showModal(MODALS.WEB_BIRTH_CHART));
  };
};

export const initUpsails = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const types = getAvailablePDFReports(state);

    const {
      upsails: { isViewed },
      remoteConfig: { notificationCenter: config },
    } = state;

    let isEnabled = false;

    if (!_.isEmpty(config)) {
      isEnabled = config?.pdfReports?.enabled;
    }

    if (isEnabled && types.length && !isViewed) {
      dispatch(addNotificationToQueue(NOTIFICATIONS.PERSONAL_REPORTS));
    }

    dispatch(showGetPDFReportsModal());
  };
};

export const downloadReport = (product: PDF_REPORTS, eventParams?: object): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      const idfm = await DeviceProps.getIDFM();
      const fileUrl = prepareReportDownloadLink(product, idfm);

      Linking.openURL(fileUrl);

      Analytics.trackEvent('Download_Report', 'Success', { ...eventParams });

      dispatch(setViewed(true));
    } catch (error) {
      console.log('[ERROR DOWNLOAD REPORTS]', error);
    }
  };
};

export const openReport = (report: PDF_REPORTS, place: OpenReportPlace): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    Analytics.track('PersonalReport_Link_Click', { report, place });

    const state = getState();

    const idfm = await DeviceProps.getIDFM();
    const sign = selectZodiacSign(state);

    const product: PersonalReport = getPersonalReportsData()[report];

    const pathName = product.pathName;
    const partUrl = report === PDF_REPORTS.COMPATIBILITY ? sign : 'report';

    const link = `https://${pathName}.astroline.today/${partUrl}?idfm=${idfm}&hideAppButton=true&isNativeApp=${!isWeb}`;

    dispatch(setViewed(true));
    navigate(ROUTES.REPORTS_WEB_VIEW, { url: link, place, report });
  };
};

export const openReports = (place: OpenReportPlace): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const reports = getAvailablePDFReports(state);

    // SHOW MODAL FOR OPEN MULTIPLE FILES
    if (reports.length > 1) {
      dispatch(showModal(MODALS.PERSONAL_REPORTS));
    } else {
      dispatch(openReport(reports[0], place));
    }
  };
};

export const showGetPDFReportsModal = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    if (!isWeb) {
      return false;
    }

    const state = getState();
    const { sessionNumber, showSessionsLimit, timeout } = state.remoteConfig.pdfReportsModalConfig || {};
    const isOnboardingCompleted = state.onboarding.isOnboardingCompleted;
    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;
    const endSession = sessionNumber + showSessionsLimit;

    const types = getAvailablePDFReports(state);

    if (!types.length && currentSession >= sessionNumber && currentSession <= endSession && isOnboardingCompleted) {
      setTimeout(() => {
        const {
          navigation: { currentRoute },
          modals: { activeModal },
        } = getState();

        if (!activeModal && !ROUTES.MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
          dispatch(showModal(MODALS.GET_PDF_GUIDE));
        }
      }, timeout * 1000);
    }
  };
};
