import React, { FC } from 'react';
import { I18nManager, Image, ImageSourcePropType, ImageStyle, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import Touchable from '../reusable/touchable';
import { headerMarginHorizontal } from '../utils/sizes';

interface Props {
  onPress: () => void;
  source: ImageSourcePropType;
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
}

const CloseButton: FC<Props> = ({ onPress, source, style, iconStyle }) => {
  return (
    <Touchable style={[styles.close, style]} onPress={onPress}>
      <Image source={source} resizeMode={'contain'} style={[styles.icon, iconStyle]} />
    </Touchable>
  );
};

export default CloseButton;

const styles = StyleSheet.create({
  close: {
    paddingVertical: 10,
    paddingRight: 15,
    paddingLeft: 10 + headerMarginHorizontal,
  },
  icon: {
    transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
  },
});
