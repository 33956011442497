import { Platform } from 'react-native';
import RNRestart from 'react-native-restart';
import { createAction } from 'redux-actions';
import dayjs from 'dayjs';
import { DEFAULT_LOCATION } from '@wowmaking/birthchart';

import { AppDispatch, AppGetState } from 'store';
import Localization from 'localization';
import { UpdatingProfileParams } from 'store/profile/types';
import { setHoroscopeSubscriptionData } from 'store/astrologers/horoscope/actions';

import Analytics from '../../analytics';
import { update, removeProfile, setProfileUserParams } from '../../api/profile';
import { ProfileData, Profile, UserParams } from '../../api/profile/interfaces';
import { GENDERS_IDS, MARITAL_STATUS_IDS } from '../../constants/profile';
import Messaging from '../../messaging';
import { checkAndSetFullProfile, toggleOverlayLoaderActivity } from '../app/actions';
import { setBirthTime, setBirthPlace, checkBirthChartOnboardingState } from '../birth-chart/actions';
import { resetOnboardingData } from '../onboarding/actions';
import { resetAuthData } from '../auth/actions';

import { PROFILE_TYPES } from './types';

export const updateUserParams = createAction(PROFILE_TYPES.UPDATE_USER_PARAMS);
export const setErrorProfile = createAction(PROFILE_TYPES.INIT_FAIL);
export const setPendingProfile = createAction(PROFILE_TYPES.SET_PENDING);
export const setUtcOffset = createAction(PROFILE_TYPES.SET_UTC_OFFSET);
export const resetUserProfileData = createAction(PROFILE_TYPES.RESET_USER_DATA);
export const setUserLoyal = createAction(PROFILE_TYPES.SET_USER_LOYAL);

export function checkAndSetUtcOffset() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: { profileData, isUtcOffsetSet },
    } = getState();

    // Нужно для корректного обновления гороскопов, у веб юзеров нет utc_offset
    // isUtcOffsetSet - на случай если у пользователя тайм зона +0
    if (!isUtcOffsetSet && !profileData?.utc_offset) {
      Analytics.trackEvent('profile', 'user_have_no_utc_offset');
      dispatch(setUtcOffset());
      dispatch(updateProfile());
    }
  };
}

export const setUserParams = (userParams: UserParams = {}) => {
  return async (dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      dispatch(updateUserParams(userParams));
      await setProfileUserParams(userParams);
    } catch (error) {
      console.log('[ERROR SET USER PARAMS]', error);
    }
  };
};

export function updateProfile(profile: UpdatingProfileParams = {}) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setPendingProfile());
    const {
      profile: { profileData },
      birthChart: { birthPlace, birthTime },
    } = getState();

    const gender = profile.gender ? profile.gender : profileData.gender;
    const relationship = profile.relationship ? profile.relationship : profileData.relationship;
    const time = profile.time ? profile.time : birthTime;
    const place = profile.place ? profile.place : birthPlace;
    const locale = Localization.getLngCode();
    const systemLocale = Localization.getSystemLngCode();

    const profileParams = {
      ...profile,
      name: profile?.name ? profile?.name : profileData.name,
      gender: GENDERS_IDS.findIndex((id: string) => id === gender),
      birthdate: profile.date ? profile.date : profileData.date,
      marital_status: MARITAL_STATUS_IDS.findIndex((id: string) => id === relationship),
      lat: place.lat,
      lon: place.lon,
      birth_place: place.name,
      birthtime: time ? parseInt(time.split(':')[0], 10) * 60 + parseInt(time.split(':')[1], 10) : 0,
      lang: locale,
      deviceLang: systemLocale,
    } as Profile;

    try {
      const data = await update(profileParams);
      Analytics.trackEvent('profile_update', 'success');

      dispatch(setProfile(data));
    } catch (e: any) {
      const error = await e.json();
      console.warn('ERROR updateProfile', error);
      Analytics.trackEvent('profile_update', 'error', { message: e?.message });
      dispatch(setErrorProfile());
    }
  };
}

export function updateProfileLang(locale = Localization.getLngCode()) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: {
        profileData: { lang },
      },
    } = getState();

    try {
      if (lang !== locale) {
        await Localization.setLng(locale);
        await dispatch(updateProfile());
        Analytics.trackEvent('profile', 'locale_changed', { newLocale: locale });
      }
    } catch (e) {
      console.log('> Update profile lang error', e);
    }
  };
}

export function setProfile(data: ProfileData) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      pwa: { installed: pwaInstalled },
      remoteConfig: { advisorHoroscopeEnabled, advisorHoroscopeChatMsgEnabled },
    } = getState();

    const { profile, signs } = data;
    const {
      id,
      name,
      device_id,
      gender,
      birthdate,
      birthtime,
      marital_status,
      utc_offset,
      lang,
      birth_place,
      lat,
      lon,
      email,
      data: userParams,
    } = profile;

    dispatch({
      type: PROFILE_TYPES.SET_PROFILE,
      payload: {
        signs,
        email,
        id,
        name: name || 'Anonymous',
        lang,
        device_id,
        gender: GENDERS_IDS[gender],
        date: birthdate,
        relationship: MARITAL_STATUS_IDS[marital_status],
        utc_offset,
        userParams,
      },
    });

    Localization.setLng(lang);

    if (userParams?.horoscopes_subscriptions_data && userParams?.horoscopes_advisor_id) {
      const subscriptionsData = JSON.parse(userParams.horoscopes_subscriptions_data);
      dispatch(setHoroscopeSubscriptionData(subscriptionsData));
    }

    let userParamsForUpdate: UserParams = {
      last_start: dayjs().format(),
    };

    if (pwaInstalled && !userParams?.pwa_installed) {
      userParamsForUpdate = {
        ...userParamsForUpdate,
        pwa_installed: true,
      };
    }

    if (advisorHoroscopeEnabled) {
      userParamsForUpdate = {
        ...userParamsForUpdate,
        horoscopes_paused: false,
        horoscopes_chat_msg_enabled: advisorHoroscopeChatMsgEnabled,
      };
    }

    const birthtimeValue = birthtime ?? 0;
    await dispatch(
      setBirthTime(`${adjustTime(Math.min(Math.floor(birthtimeValue / 60), 23).toString())}:${adjustTime((birthtimeValue % 60).toString())}`),
    );

    if (birth_place) {
      dispatch(
        setBirthPlace({
          name: birth_place,
          lat: +(lat || DEFAULT_LOCATION.lat),
          lon: +(lon || DEFAULT_LOCATION.lon),
        }),
      );
    }

    dispatch(setUserParams(userParamsForUpdate));
    dispatch(checkBirthChartOnboardingState());
    dispatch(checkAndSetFullProfile());
  };
}

export function logoutAndRemoveUserProfile(isRemoveProfile: boolean) {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
    } = getState();

    dispatch(toggleOverlayLoaderActivity());

    try {
      Messaging.unscheduleAllNotifications();
      dispatch(resetOnboardingData());
      if (isRemoveProfile) {
        await removeProfile();
      }
      dispatch(resetAuthData());
      dispatch(resetUserProfileData());

      if (webUUID) {
        // reset web condition
        Analytics.setUserProperty('web_source', '');
        if (Platform.OS === 'web') {
          const params = new URLSearchParams(window.location.search);
          // Delete the query parameter.
          params.delete('source');
          params.delete('idfm');
          history.replaceState(null, '', '?' + params + window.location.hash);
        }
      }

      setTimeout(() => {
        RNRestart.Restart();
      }, 200);
    } catch (e) {
      console.warn('ERROR removeUserProfile', e);
      dispatch(setErrorProfile());
    }
  };
}

function adjustTime(s: string) {
  s = String(s);
  return (s.length < 2 ? '0' : '') + s;
}
