import type { GradientPresets } from './model';

export const gradientPresets: GradientPresets = {
  gold: {
    colors: ['#E5A87F', '#F7E6C4', '#E5A87F'],
    locations: [0.18, 0.51, 1],
  },
  'light-gold': {
    colors: ['#FFFFFF', '#FFE8A9'],
    locations: [0.32, 1],
    gradientDirection: {
      x1: 1,
      y1: 0,
      x2: 0,
      y2: 1,
    },
  },
};
