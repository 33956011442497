import { State, ActionTypes, SET_VIEWED } from './types';

const initialState: State = {
  isViewed: false,
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET_VIEWED:
      return {
        ...state,
        isViewed: action.payload,
      };
    default:
      return state;
  }
};
