import { Dimensions, Platform, StatusBar } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';

const { width, height } = Dimensions.get('window');

const IPHONE_X_HEIGHT = 812;
const IPHONE_X_WIDTH = 375;

export const headerIconSize = (width / 100) * 7.5;
export const headerMarginHorizontal = (width / 100) * 2.5;
export const statusbarHeight = Platform.select({
  ios: isIphoneX() ? 44 : 20,
  android: StatusBar.currentHeight,
  web: 0,
});

export const getRelativeHeight = sourceHeight =>
  Math.round(Math.sqrt(height / IPHONE_X_HEIGHT) * sourceHeight);
export const getRelativeWidth = sourceWidth =>
  Math.round(Math.sqrt(width / IPHONE_X_WIDTH) * sourceWidth);
