import { Platform } from 'react-native';

import { BUNDLE_ID } from './general';

export const KEY = Platform.select({
  ios: '3d2176c3d9c44b5a95291b7dd0ab9055',
  android:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwYXkPRh8N5rZm1yJUC9aHs3eP9cSWllYmDUQ6WA2rr1cdRCOoKE9wij9GnTnL4s+T/EJKaAf19PNQonri77M5Y3Bjt3h+6Lgv1UcBaVi74bFL/SxywaS7Nhri0poFt2zON0T2CN9tVMdJhHry58MckkEbhaU3f319I8Eg8AzDCNAP5qRdCJ4BFR1Mm44ZIrZUGm5WX5HXh2/9FpPmkc9Cs2jBkw1YRXQ6t/De8PRos1Lfcmmzrg6otZF0y4F3l7FlCsSNw5HL3ML5Q6dATql5LuzmKtOJ+dXTrJOftor0ASxBoITnOdXFDngavpDuvlBsxh7MvJA0pZWTbEWlkVdhwIDAQAB',
});

export const FAMILY_POSTFIX = '.fm';
export const SKU = BUNDLE_ID + '.premium';
export const PRO = BUNDLE_ID + '.pro';
export const OFFER = BUNDLE_ID + '.promotional.offer';

export const WEEK = SKU + '.w.a';
export const WEEK_A = SKU + '.weekly.a';
export const WEEK_G = SKU + '.w.g';
export const WEEK_N = SKU + '.w.n';
export const WEEK_H = SKU + '.w.h';
export const WEEK_F = SKU + '.w.f';
export const WEEK_E = SKU + '.w.e';
export const WEEK_L = SKU + '.w.l';
export const MONTH = SKU + '.m.a';
export const MONTH_C = SKU + '.m.c';
export const MONTH_D = SKU + '.m.d';
export const MONTH_F = SKU + '.m.f';
export const YEAR = SKU + '.y.a';
export const YEAR_E = SKU + '.y.e';
export const YEAR_F = SKU + '.y.f';
export const YEAR_D = SKU + '.y.d';
export const YEAR_C = SKU + '.y.c';
export const YEAR_H = SKU + '.q.b';
export const FAMILY_WEEK = SKU + `.w${FAMILY_POSTFIX}.a`;
export const FAMILY_MONTH = SKU + `.m${FAMILY_POSTFIX}.a`;
export const FAMILY_YEAR = SKU + `.y${FAMILY_POSTFIX}.a`;
export const LIFETIME = SKU + '.lifetime.c';
export const PRO_W = SKU + '.w.pro';

export const OFFERS = {
  T: OFFER + '.t',
  T_A: OFFER + '.t.a',
  T_B: OFFER + '.t.b',
};

export const OFFERS_BY_PRODUCT = {
  PRO_W: [OFFERS.T, OFFERS.T_A, OFFERS.T_B],
};

export const FAMILY_SUBS = Platform.OS === 'ios' ? [FAMILY_WEEK, FAMILY_MONTH, FAMILY_YEAR] : [];

export const SUBSCRIPTION_IDS = [
  WEEK,
  WEEK_G,
  WEEK_A,
  WEEK_N,
  WEEK_H,
  WEEK_F,
  WEEK_E,
  WEEK_L,
  MONTH,
  MONTH_C,
  MONTH_D,
  MONTH_F,
  YEAR_E,
  YEAR_D,
  YEAR_C,
  YEAR_H,
  YEAR_F,
  PRO_W,
  ...FAMILY_SUBS,
];

export const PRODUCT_IDS = [LIFETIME];
