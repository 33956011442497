import { AspectId, PlanetId, ASCENDANT } from '@wowmaking/birthchart';
import type { Ascendant } from '@wowmaking/birthchart';

import ASCENDANT_IMG from './images/ascendant.png';
import JUPITER_IMG from './images/jupiter.png';
import MARS_IMG from './images/mars.png';
import MERCURY_IMG from './images/mercury.png';
import MOON_IMG from './images/moon.png';
import NEPTUNE_IMG from './images/neptune.png';
import PLUTO_IMG from './images/pluto.png';
import SATURN_IMG from './images/saturn.png';
import SUN_IMG from './images/sun.png';
import URANUS_IMG from './images/uranus.png';
import VENUS_IMG from './images/venus.png';
import { PlanetImages } from './interfaces';

export const PLANET_IMAGES: PlanetImages = {
  [ASCENDANT]: ASCENDANT_IMG,
  [PlanetId.Jupiter]: JUPITER_IMG,
  [PlanetId.Mars]: MARS_IMG,
  [PlanetId.Mercury]: MERCURY_IMG,
  [PlanetId.Moon]: MOON_IMG,
  [PlanetId.Neptune]: NEPTUNE_IMG,
  [PlanetId.Pluto]: PLUTO_IMG,
  [PlanetId.Saturn]: SATURN_IMG,
  [PlanetId.Sun]: SUN_IMG,
  [PlanetId.Uranus]: URANUS_IMG,
  [PlanetId.Venus]: VENUS_IMG,
};

export const CHART_MODAL_INFO = {
  TITLE: 'BIRTH_CHART.MODAL.TITLE',
  POINTS: [
    {
      id: 'DESC_0',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_0',
    },
    {
      id: 'DESC_1',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_1',
    },
    {
      id: 'DESC_2',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_2',
    },
  ],
};

export const TOP_TABS_SCREEN_OPTIONS = { swipeEnabled: false };

export enum TOP_TABS_IDS {
  TRANSITS = 'transits',
  CHARTS = 'charts',
}

export const TOP_TABS: TOP_TABS_IDS[] = [TOP_TABS_IDS.TRANSITS, TOP_TABS_IDS.CHARTS];

export const PERSONALITY_PLANETS: (PlanetId | Ascendant)[] = [PlanetId.Sun, PlanetId.Moon, ASCENDANT];

export const STELLAR_PLANETS: PlanetId[] = [
  PlanetId.Mercury,
  PlanetId.Venus,
  PlanetId.Mars,
  PlanetId.Jupiter,
  PlanetId.Saturn,
  PlanetId.Uranus,
  PlanetId.Neptune,
  PlanetId.Pluto,
];

export const ASPECTS_MAJOR: AspectId[] = [AspectId.Conjunction, AspectId.Opposition, AspectId.Trine, AspectId.Square];
export const ASPECTS_MINOR: AspectId[] = [AspectId.Quincunx, AspectId.Quintile, AspectId.Semisextile, AspectId.Semisquare];

export const MIN_BIRTH_DATE = '1920-01-01';
