export const COMING_SOON = 'COMING_SOON';
export const SPECIAL_GUIDE = 'SPECIAL_GUIDE';
export const SURVEY = 'SURVEY';
export const SKIP_SURVEY = 'SKIP_SURVEY';
export const VIDEO_PLAYER = 'DATE_PICKER';
export const JOURNAL_MODAL = 'JOURNEY_MODAL';
export const RATE_SESSION = 'RATE_SESSION';
export const SOUNDS = 'SOUNDS';
export const LUNAR_CALENDAR = 'SOUNDS';
export const RATE_US = 'RATE_US';
export const TRUST_PILOT_REVIEW = 'TRUST_PILOT_REVIEW';
export const ASTRO_CALENDAR_RATE_US = 'ASTRO_CALENDAR_RATE_US';
export const SKIP_SESSION = 'SKIP_SESSION';
export const SESSION_FORMAT = 'SESSION_FORMAT';
export const EMOJIS = 'EMOJIS';
export const ARTICLE = 'ARTICLE';
export const GESTURES = 'GESTURES';
export const FEEDBACK = 'FEEDBACK';
export const ASTROLOGERS_QUESTION = 'ASTROLOGERS_QUESTION';
export const ASTROLOGERS_EMAIL = 'ASTROLOGERS_EMAIL';
export const PERSONAL_REPORTS = 'PERSONAL_REPORTS';
export const REACTIVATION = 'REACTIVATION';
export const REACTIVATION_THX = 'REACTIVATION_THX';
export const GET_PDF_GUIDE = 'GET_PDF_GUIDE';
export const WEB_BIRTH_CHART = 'WEB_BIRTH_CHART';
export const PWA_INSTRUCTION = 'PWA_INSTRUCTION';
export const FREE_CONSULTATION = 'FREE_CONSULTATION';
export const ADVISOR_ASTRO_EVENT = 'ADVISOR_ASTRO_EVENT';
export const OFFER_WELCOME = 'OFFER_WELCOME';
export const MESSENGERS = 'MESSENGERS';
export const AUTO_REFILL = 'AUTO_REFILL';
export const ONE_TIME = 'ONE_TIME';
export const BIRTH_CHART_TRANSITS = 'BIRTH_CHART_TRANSITS';
export const HOUSE_DESCRIPTION = 'HOUSE_DESCRIPTION';
export const TABLE_HOUSE_DESCRIPTION = 'TABLE_HOUSE_DESCRIPTION';
export const TODAY_MATCHES = 'TODAY_MATCHES';
export const INSTALL_MOBILE_APP = 'INSTALL_MOBILE_APP';
export const ADVISOR_SERVICE_INFO = 'ADVISOR_SERVICE_INFO';
export const ASTRO_CALENDAR_CONTENT_INFO = 'ASTRO_CALENDAR_CONTENT_INFO';
export const ASTRO_CALENDAR_TUTORIAL = 'ASTRO_CALENDAR_TUTORIAL';
export const ASTRO_CALENDAR_PDF_GUIDE_BONUS = 'ASTRO_CALENDAR_PDF_GUIDE_BONUS';
export const RATE_TRUST_PILOT = 'RATE_TRUST_PILOT';
export const CHANGE_ADVISOR_SUBSCRIPTION = 'CHANGE_ADVISOR_SUBSCRIPTION';
export const BIRTH_CHART_REPORT = 'BIRTH_CHART_REPORT';
