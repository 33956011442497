import React, { memo, useCallback, useEffect } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { sw, paddingVertical, paddingHorizontal, fs, color } from '@wowmaking/ui/src/utils';

import { t } from 'localization';
import Analytics from 'analytics';
import { useAppDispatch } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import Button from 'components/button';
import Icon from 'components/icon';
import Text from 'components/text';
import useModalIsVisible from 'hooks/use-modal-is-visibly';
import ProLabel from 'components/pro-label';
import { PDF_REPORTS } from 'constants/upsale';
import PersonalReportItem from 'components/personal-reports-banner/components/personal-report-item';
import { openReport } from 'store/upsails/actions';
import { setBirthChartPDFReportShown } from 'store/birth-chart/actions';

const BirthChartReportModal = () => {
  const dispatch = useAppDispatch();
  const isVisible = useModalIsVisible(MODALS.BIRTH_CHART_REPORT);

  useEffect(() => {
    if (isVisible) {
      Analytics.track('BirthChartReportModal_Open');
    }
  }, [isVisible]);

  const handleClosePress = useCallback(() => {
    dispatch(setBirthChartPDFReportShown());
    dispatch(closeCurrentModal());
  }, [dispatch]);

  const handleOpenReport = useCallback(() => {
    dispatch(openReport(PDF_REPORTS.BIRTH_CHART, 'birth-chart-modal'));
    handleClosePress();
  }, [dispatch, handleClosePress]);

  return (
    <Modal isVisible={isVisible} backdropOpacity={0.8} style={styles.wrapper} animationIn="slideInUp" animationOut="slideOutDown">
      <View style={styles.root}>
        <TouchableOpacity style={styles.iconWr} onPress={handleClosePress}>
          <Icon name={'cross'} color={COLORS.BEIGE_2} size={sw(15)} />
        </TouchableOpacity>

        <ProLabel size={'large'} />

        <Text font="Philosopher" style={styles.title}>
          {t('MODALS.BIRTH_CHART_REPORT.TITLE')}
        </Text>

        <Text style={styles.subtitle}>{t('MODALS.BIRTH_CHART_REPORT.SUBTITLE')}</Text>

        <View style={styles.reports}>
          <PersonalReportItem report={PDF_REPORTS.BIRTH_CHART} onPress={handleOpenReport} giftIcon />
          <Text style={styles.text}>{t('MODALS.BIRTH_CHART_REPORT.FIND_REPORT')}</Text>
        </View>

        <Button title={t(`MODALS.BIRTH_CHART_REPORT.BUTTON`)} onPress={handleOpenReport} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  root: {
    borderTopRightRadius: sw(40),
    borderTopLeftRadius: sw(40),
    paddingHorizontal: paddingHorizontal(25),
    paddingBottom: paddingVertical(30),
    paddingTop: paddingVertical(55),
    backgroundColor: COLORS.DARK_TEAL,
    alignItems: 'center',
  },
  iconWr: {
    position: 'absolute',
    right: paddingHorizontal(20),
    top: paddingVertical(20),
  },
  title: {
    color: COLORS.BEIGE_2,
    fontSize: fs(26),
    lineHeight: fs(32),
    fontWeight: '700',
    textAlign: 'center',
    marginVertical: paddingVertical(10),
  },
  subtitle: {
    color: color(COLORS.BEIGE, 0.8),
    textAlign: 'center',
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '400',
    marginBottom: paddingVertical(30),
  },
  reports: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(5),
  },
  text: {
    color: color(COLORS.BEIGE, 0.5),
    textAlign: 'center',
    fontSize: fs(13),
    lineHeight: fs(18),
    fontWeight: '400',
    marginTop: paddingVertical(5),
    marginBottom: paddingVertical(40),
  },
});

export default memo(BirthChartReportModal);
