import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { getWebOneTimePurchaseProducts } from 'store/billing/selectors';
import { PDF_REPORTS_BY_PRODUCT, ULTRA_2_PDF_REPORTS, PDF_REPORTS } from 'constants/upsale';
import { RootState } from 'store';

const getPurchased = (state: RootState) => state.billing.purchased;
const getBirthChartReportInSubEnabled = (state: RootState) => state.remoteConfig.birthChartReportInSubEnabled;

export const getBirthChartPDFReport = createSelector(
  [getPurchased, getBirthChartReportInSubEnabled],
  (purchased, birthChartReportInSubEnabled): boolean => {
    return purchased && birthChartReportInSubEnabled;
  },
);

export const getAvailablePDFReports = createSelector(
  [getWebOneTimePurchaseProducts, getBirthChartPDFReport],
  (onetimeProducts, birthChartPDFReportEnaled): PDF_REPORTS[] => {
    const reportProducts = onetimeProducts.reduce((reports: PDF_REPORTS[], onetimeProduct) => {
      const codes = PDF_REPORTS_BY_PRODUCT[onetimeProduct as keyof typeof PDF_REPORTS_BY_PRODUCT];

      if (!isEmpty(codes)) {
        for (const code in codes) {
          if (!reports?.includes?.(codes[code])) {
            reports.push(codes[code] as unknown as PDF_REPORTS);
          }
        }
      }

      return reports;
    }, []);

    if (birthChartPDFReportEnaled && !reportProducts.includes(PDF_REPORTS.BIRTH_CHART)) {
      reportProducts.push(PDF_REPORTS.BIRTH_CHART);
    }

    return reportProducts;
  },
);

export const getUnavailablePDFReportProducts = createSelector([getWebOneTimePurchaseProducts], (products): PDF_REPORTS[] => {
  const availableReportsProducts = products.flatMap(productCode => PDF_REPORTS_BY_PRODUCT[productCode as keyof typeof PDF_REPORTS_BY_PRODUCT] || []);

  const notAvailableReportsProducts = ULTRA_2_PDF_REPORTS.filter(reportProduct => !availableReportsProducts?.includes?.(reportProduct));

  return notAvailableReportsProducts;
});
