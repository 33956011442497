import { Platform } from 'react-native';
import _ from 'lodash';
import moment from 'moment';

import { RootState } from 'store';
import { MONETIZATION_TYPES } from 'constants/monetization-types';
import { PROMO_INCLUDE_TRIGGERS } from 'constants/monetization-triggers';

export function checkMonetizationConfig(state: RootState, block: string, additionalKey = null) {
  const { monetizationConfig } = state.remoteConfig;
  const { purchased } = state.billing;
  const unlockContent = state.unlockContent;

  if (Platform.OS !== 'web' && purchased) {
    return null;
  }

  const unlockBlock = additionalKey ? `${block}_${additionalKey}` : block;
  const unlockEndDate = moment(_.get(unlockContent, unlockBlock, 0));

  if (!monetizationConfig[block] || unlockEndDate > moment()) {
    return null;
  }

  return monetizationConfig[block];
}

export function isPromoEnabledForTrigger(state: RootState, block: string, additionalKey = null) {
  const { monetizationConfig } = state.remoteConfig;
  const unlockContent = state.unlockContent;

  const unlockBlock = additionalKey ? `${block}_${additionalKey}` : block;
  const unlockEndDate = moment(_.get(unlockContent, unlockBlock, 0));

  if (unlockEndDate > moment()) {
    return false;
  }

  if (PROMO_INCLUDE_TRIGGERS.includes(block)) {
    return true;
  }

  if (!monetizationConfig[block]) {
    return false;
  }

  return monetizationConfig[block]?.[0]?.type === MONETIZATION_TYPES.SUBSCRIPTION;
}
