import { DEFAULT_TIME } from 'constants/notifications';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  isPermissionRequested: false,
  isWebNotificationRequestOverlayVisible: false,
  fcmTokenHash: '',
  notificationTime: DEFAULT_TIME,
  dataFromPush: null,
};

export default function (state: State = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_PERMISSION_REQUESTED:
      return {
        ...state,
        isPermissionRequested: true,
      };
    case TYPES.SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY:
      return {
        ...state,
        isWebNotificationRequestOverlayVisible: action.payload,
      };
    case TYPES.SET_NOTIFICATION_TIME:
      return {
        ...state,
        notificationTime: action.payload,
      };
    case TYPES.SET_FCM_TOKEN_HASH:
      return {
        ...state,
        fcmTokenHash: action.payload,
      };

    case TYPES.SET_DATA_FROM_PUSH:
      return {
        ...state,
        dataFromPush: action.payload,
      };

    default:
      return state;
  }
}
