export const CLOSE_ICON = require('../../assets/icons/close.png');
export const CHANGE_HAND = require('../../assets/icons/icon-change-hand.png');
export const SHOT_ICONS = require('../../assets/icons/button.png');
export const BACK_ICON = require('../../assets/icons/icon-back.png');
export const ICON_ARROW_UP = require('../../assets/icons/arrows/arrow-up-large.png');
export const ICON_ARROW_DOWN = require('../../assets/icons/arrows/arrow-down-large.png');
export const NEXT_ARROW = require('../../assets/icons/arrows/next-arrow.png');
export const RIGHT_ARROW = require('../../assets/icons/arrows/right-arrow.png');
export const ICON_NO_CONNECTION = require('../../assets/icons/icon-no-connect.png');
export const ICON_CORRECT_SMALL = require('../../assets/icons/icon-correct-small.png');
export const ICON_INCORRECT_SMALL = require('../../assets/icons/icon-incorrect-small.png');
export const ARROW_NEXT = require('../../assets/icons/arrow-next.png');
export const PLUS = require('../../assets/icons/icon-plus.png');
export const SETTINGS_NEXT = require('../../assets/icons/icon-settings-next.png');
export const SETTINGS_RATE_US = require('../../assets/icons/icon-rate.png');
export const APP_ICON_DEFAULT = require('../../assets/icons/app-icons/default.png');
