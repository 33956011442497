import { Moon } from 'lunarphase-js';
import dayjs from 'dayjs';
import { PlanetId } from '@wowmaking/birthchart';
import type { Transit } from '@wowmaking/birthchart';

import { t, t2 } from 'localization';
import { ISODate } from 'interfaces/date';
import { MOON_PHASE_ICONS, MOON_PHASE_SHORT_NAME } from 'screens/calendars/constants';
import { upperCase } from 'utils/strings';

import { ASTRO_CALENDAR_EVENT_TYPE, ASTRO_EVENT_STATUS, LUNAR_PHASES, RETROGRADE_STORIES_CATEGORY_SLUG } from '../constants';
import type {
  AstroEvent,
  AstroEventInputData,
  LunarPhase,
  LunarPhaseEvent,
  TransitEvent,
  RetrogradePlanet,
  RetrogradePlanetEvent,
} from '../interfaces';

/* General */

export const generateAstroEventsForDataDay = (date: ISODate, inputs: AstroEventInputData): AstroEvent[] => {
  return [generateLunarPhaseEvent(date), generateTransitEvent(date, inputs), generateRetrogradePlanetEvent(date, inputs)].filter(isAstroEvent);
};

export const isAstroEvent = (event: any): event is AstroEvent => {
  return event && Object.values(ASTRO_CALENDAR_EVENT_TYPE).includes(event.type);
};

export const getAstroEventStatus = (date: ISODate): ASTRO_EVENT_STATUS => {
  const maxPastDay = dayjs().add(-2, 'days');
  const maxFutureDay = dayjs().add(2, 'days');

  if (dayjs(date).isBefore(maxPastDay, 'day')) {
    return ASTRO_EVENT_STATUS.PASSED;
  }

  if (dayjs(date).isAfter(maxFutureDay, 'day')) {
    return ASTRO_EVENT_STATUS.FUTURE;
  }

  return ASTRO_EVENT_STATUS.AVAILABLE;
};

/* Lunar phase */

export const generateLunarPhaseEvent = (date: ISODate): LunarPhaseEvent | null => {
  const phase = convertStrToLunarPhase(Moon.lunarPhase(new Date(date)));

  if (phase === null) {
    return null;
  }

  return {
    type: ASTRO_CALENDAR_EVENT_TYPE.LUNAR_PHASE,
    date,
    phase,
    title: t2(MOON_PHASE_SHORT_NAME[phase]),
    subtitle: t('ASTRO_CALENDAR.ASTRO_EVENT.LUNAR_PHASE.SUBTITLE'),
    img: MOON_PHASE_ICONS[phase],
  };
};

export const convertStrToLunarPhase = (phase: string): LunarPhase | null => {
  let phaseName = phase.toLowerCase().split(' ').join('-');

  if (!phaseName.includes('-')) {
    phaseName += '-moon';
  }

  return isLunarPhase(phaseName) ? phaseName : null;
};

export const isLunarPhase = (phase: any): phase is LunarPhase => LUNAR_PHASES.includes(phase);

/* Transit */

export const generateTransitEvent = (date: ISODate, data: AstroEventInputData): TransitEvent | null => {
  const { transitsData } = data;

  const transit = transitsData[date];

  if (!transit) {
    return null;
  }

  return {
    type: ASTRO_CALENDAR_EVENT_TYPE.TRANSIT,
    date,
    transit,
    title: getTransitTitle(transit),
    subtitle: t('ASTRO_CALENDAR.ASTRO_EVENT.TRANSIT.SUBTITLE'),
    img: transit.transitPlanet.name,
  };
};

const getTransitTitle = (transit: Transit): string => {
  const { natalPlanet, aspect, transitPlanet } = transit;

  const planetName = t(`SINGS.PLANETS.${upperCase(transitPlanet.name)}`);
  const natalPlanetName = t(`SINGS.PLANETS.${upperCase(natalPlanet.name)}`);
  const aspectName = t(`TRANSITS.ASPECTS_NAMES.${upperCase(aspect.name)}`);

  return `${planetName} ${aspectName} ${t('TRANSITS.DESCRIPTION.ARTICLE')} ${natalPlanetName}`;
};

/* Retrograde Planet */

export const generateRetrogradePlanetEvent = (date: ISODate, data: AstroEventInputData): RetrogradePlanetEvent | null => {
  const { retrogradesContent, stories } = data;

  const retrogradeStories = stories.find(story => story.slug === RETROGRADE_STORIES_CATEGORY_SLUG);

  if (!retrogradeStories?.id) {
    return null;
  }

  const activeRetrogrades = retrogradesContent.filter(item => dayjs(date).isBetween(item.from_date, item.to_date));

  const sortedRetrogrades = activeRetrogrades.sort((a, b) =>
    getRetrogradePlanetWeight(a.planet_type) > getRetrogradePlanetWeight(b.planet_type) ? -1 : 1,
  );

  if (!sortedRetrogrades.length) {
    return null;
  }

  const retrograde = sortedRetrogrades[0];

  return {
    type: ASTRO_CALENDAR_EVENT_TYPE.RETROGRADE_PLANET,
    date,
    retrograde,
    title: `${t2(`SINGS.PLANETS.${retrograde.planet_type.toUpperCase()}`)} ${t('ASTRO_CALENDAR.ASTRO_EVENT.RETROGRADE_PLANET.TITLE')}`,
    subtitle: t('ASTRO_CALENDAR.ASTRO_EVENT.RETROGRADE_PLANET.SUBTITLE'),
    img: { uri: retrograde.icon },
  };
};

const getRetrogradePlanetWeight = (planet: RetrogradePlanet): number => {
  switch (planet) {
    case PlanetId.Mercury:
      return 3;
    case PlanetId.Venus:
      return 2;
    default:
      return 1;
  }
};
