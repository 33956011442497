import React, { FC, memo } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';
import { sw, paddingVertical, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';

import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { BEIGE_2, DARK_BLUE_GREY, TOUPE, WHITE, GRADIENT_AQUA_TO_PURPLE, GRADIENT_DARK_PURPLE_TO_DARK_GREEN } from 'constants/colors';
import * as MODALS from 'constants/modals';
import Analytics from 'analytics';
import Text from 'components/text';
import Button from 'components/button';
import { closeAstroeventModal, startAstroeventChat } from 'store/astrologers/modals/actions';
import CLOSE from 'assets/icons/close.png';
import useModalIsVisible from 'hooks/use-modal-is-visibly';
import AdvisorAvatar from 'components/advisors/avatar';

import TRANSIT from './images/transit.png';

interface Props {}

const AdvisorAstroevent: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isAdvisorAstroeventModalVisible = useModalIsVisible(MODALS.ADVISOR_ASTRO_EVENT);
  const { title, description, duration } = useSelector((state: RootState) => state.transits.astroeventTransit);
  const astrologist = useSelector((state: RootState) => state.astrologers.core.lastConnectedAstrologer);

  const handlePress = () => {
    Analytics.trackEvent('Astroevent', 'Click');
    dispatch(startAstroeventChat());
  };

  const handleModalShow = () => {
    Analytics.trackEvent('Astroevent', 'Show');
  };

  const handleClose = () => {
    Analytics.trackEvent('Astroevent', 'Close');
    dispatch(closeAstroeventModal());
  };

  if (!title || !description || !duration) {
    return null;
  }

  return (
    <Modal
      isVisible={isAdvisorAstroeventModalVisible}
      backdropOpacity={0.8}
      style={styles.wrapper}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      onModalShow={handleModalShow}>
      <View style={styles.wrap}>
        <TouchableOpacity style={styles.iconContainer} onPress={handleClose}>
          <Image style={styles.icon} source={CLOSE} />
        </TouchableOpacity>
        <Text font="Philosopher" style={styles.title}>
          {t('ASTROLOGIST_CHAT.ASTROEVENT.TITLE')}
        </Text>
        <View style={styles.transitWrapper}>
          <Image source={TRANSIT} style={styles.transitImage} />
          <View style={styles.transit}>
            <Text style={styles.transitDuration}>{duration}</Text>
            <Text font="Philosopher" style={styles.transitTitle}>
              {title}
            </Text>
            <Text style={styles.transitDescription} numberOfLines={5} ellipsizeMode="tail">
              {description}
            </Text>
          </View>
        </View>
        <View style={styles.card}>
          <View style={styles.content}>
            {astrologist && <AdvisorAvatar advisor={astrologist} size={'small'} />}

            <View style={styles.cardInfo}>
              <Text font="Philosopher" style={styles.cardTitle}>
                {!!astrologist?.name && astrologist.name}
              </Text>
              <Text style={styles.cardMessage}>{t('ASTROLOGIST_CHAT.ASTROEVENT.MESSAGE')}</Text>
            </View>
          </View>
          <LinearGradient
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 1 }}
            useAngle={true}
            angle={150}
            style={styles.gradient}
            colors={GRADIENT_AQUA_TO_PURPLE}>
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 0, y: 1 }}
              useAngle={true}
              angle={180}
              style={styles.cardBg}
              locations={[0, 1]}
              colors={GRADIENT_DARK_PURPLE_TO_DARK_GREEN}
            />
          </LinearGradient>
        </View>
        <Text style={styles.chatInfo}>{t('ASTROLOGIST_CHAT.ASTROEVENT.INFO')}</Text>
        <Button title={t('ASTROLOGIST_CHAT.ASTROEVENT.BUTTON')} onPress={handlePress} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  wrap: {
    paddingVertical: paddingVertical(20),
    borderTopRightRadius: sw(30),
    borderTopLeftRadius: sw(30),
    paddingHorizontal: paddingHorizontal(15),
    backgroundColor: DARK_BLUE_GREY,
  },
  title: {
    fontSize: fs(26),
    color: BEIGE_2,
    fontWeight: '700',
    lineHeight: fs(32),
    textAlign: 'center',
    paddingBottom: paddingVertical(20),
  },
  transitWrapper: {
    flexDirection: 'row',
    marginBottom: paddingVertical(20),
    alignItems: 'center',
  },
  transitImage: {
    width: sw(116),
    height: sw(116),
    marginRight: paddingHorizontal(15),
  },
  transit: {
    flex: 1,
  },
  transitDuration: {
    color: TOUPE,
    fontSize: fs(12),
    fontWeight: '600',
    lineHeight: fs(20),
    marginBottom: paddingVertical(5),
  },
  transitTitle: {
    color: BEIGE_2,
    fontSize: fs(16),
    fontWeight: '700',
    marginBottom: paddingVertical(10),
  },
  transitDescription: {
    color: WHITE,
    opacity: 0.8,
    fontSize: fs(12),
    fontWeight: '400',
    lineHeight: fs(18),
  },
  card: {
    width: '100%',
    padding: 1,
    position: 'relative',
    marginBottom: paddingVertical(15),
    paddingTop: paddingVertical(20),
    paddingBottom: paddingVertical(17),
    paddingLeft: paddingHorizontal(15),
    paddingRight: paddingHorizontal(10),
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    padding: 1,
    borderRadius: 20,
  },
  cardBg: {
    width: '100%',
    height: '100%',
    borderRadius: 20,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 3,
  },
  cardInfo: {
    marginLeft: paddingHorizontal(15),
    flexShrink: 1,
    flexGrow: 1,
  },
  cardTitle: {
    color: BEIGE_2,
    fontSize: fs(17),
    fontWeight: '700',
    lineHeight: fs(22),
  },
  cardMessage: {
    color: WHITE,
    opacity: 0.9,
    fontSize: fs(14),
    fontWeight: '400',
    lineHeight: fs(18),
  },
  chatInfo: {
    color: WHITE,
    opacity: 0.7,
    fontSize: fs(13),
    fontWeight: '400',
    lineHeight: fs(19),
    textAlign: 'center',
    marginBottom: paddingVertical(20),
  },
  iconContainer: {
    alignSelf: 'flex-end',
    marginBottom: paddingVertical(5),
  },
  icon: {
    tintColor: BEIGE_2,
  },
});

export default memo(AdvisorAstroevent);
