import { ResetUserData } from '../types';

interface Balances {
  balance: number;
  freeBalance: number;
}

export interface State {
  seconds: number;
  isTimeUp: boolean;
  isTimerTooltipVisible: boolean;
  isTimerTooltipClosed: boolean;
  isTimePaused: boolean;
  isAwaitingNewTimeBalance: boolean;
  lastTransactionDate: string | null;
  balances: Balances;
  chatTimer: number;
}

export enum TYPES {
  SET_AVAILABLE_SECONDS = 'ASTROLOGERS/SET_AVAILABLE_SECONDS',
  SET_TOOLTIP_VISIBILITY = 'ASTROLOGERS/SET_TOOLTIP_VISIBILITY',
  SET_TOOLTIP_CLOSED = 'ASTROLOGERS/SET_TOOLTIP_CLOSED',
  SET_TIME_PAUSED = 'ASTROLOGERS/SET_TIME_PAUSED',
  SET_AWAITING_NEW_TIME_BALANCE = 'ASTROLOGERS/SET_AWAITING_NEW_TIME_BALANCE',
  SET_LAST_TRANSACTION_DATE = 'ASTROLOGERS/SET_LAST_TRANSACTION_DATE',
  SET_BALANCES = 'ASTROLOGERS/SET_BALANCES',
  SET_CHAT_TIMER = 'ASTROLOGERS/SET_CHAT_TIMER',
}

interface SetMinutes {
  type: typeof TYPES.SET_AVAILABLE_SECONDS;
  payload: number;
}

interface SetTooltipVisibility {
  type: typeof TYPES.SET_TOOLTIP_VISIBILITY;
  payload: boolean;
}

interface SetTooltipClosed {
  type: typeof TYPES.SET_TOOLTIP_CLOSED;
  payload: boolean;
}

interface SetTimePaused {
  type: typeof TYPES.SET_TIME_PAUSED;
  payload: boolean;
}

interface SetAwaitingNewTimeBalance {
  type: typeof TYPES.SET_AWAITING_NEW_TIME_BALANCE;
  payload: boolean;
}

interface SetLastTransactionDate {
  type: typeof TYPES.SET_LAST_TRANSACTION_DATE;
  payload: string;
}

interface SetBalances {
  type: typeof TYPES.SET_BALANCES;
  payload: Balances;
}

interface SetChatTimer {
  type: typeof TYPES.SET_CHAT_TIMER;
  payload: number;
}

export type ActionType =
  | SetMinutes
  | SetTooltipVisibility
  | SetTooltipClosed
  | SetTimePaused
  | ResetUserData
  | SetAwaitingNewTimeBalance
  | SetLastTransactionDate
  | SetBalances
  | SetChatTimer;
