import _ from 'lodash';
import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import * as MODALS from 'constants/modals';
import { MODALS_EXCLUDE_ROUTES } from 'constants/routes';

import { TYPES, Modal } from './types';

const MODAL_DELAY = 5000;

const setActiveModal = createAction(TYPES.SET_ACTIVE_MODAL);
const closeModal = createAction(TYPES.CLOSE_MODAL);
const setQueue = createAction(TYPES.SET_QUEUE);

export const test = () => {
  return function (dispatch: AppDispatch) {
    dispatch(showModal(MODALS.LUNAR_CALENDAR));
  };
};

export function showFeedbackModalDependingOnSession() {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const {
      remoteConfig: { feedbackSession },
    } = getState();
    const sessionNumber = Analytics.getSessionNumber();

    if (sessionNumber && sessionNumber + 1 === feedbackSession) {
      _.delay(() => {
        const currentRoute = getState().navigation.currentRoute;

        if (!MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
          dispatch(showModal(MODALS.FEEDBACK));
        }
      }, MODAL_DELAY);
    }
  };
}

export function closeAllOpenedModals() {
  return function (dispatch: AppDispatch) {
    // For now need to call only closeCurrentModal, may be later there will be some extra logic
    return dispatch(closeCurrentModal());
  };
}

export const showModal = (name: Modal['name'], params: Modal['params'] = {}) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { activeModal },
    } = getState();

    if (activeModal?.length) {
      // If It needs for all modals
      // dispatch(addToQueue(name, params));

      return false;
    }

    dispatch(setActiveModal({ name, params }));
    return true;
  };
};

export const closeCurrentModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { activeModal },
    } = getState();

    if (activeModal?.length) {
      dispatch(closeModal());
      dispatch(removeFromQueue(activeModal));
      dispatch(showNextFromQueue());
    }
  };
};

export const addToQueue = (name: Modal['name'], params: Modal['params'] = {}) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { queue },
    } = getState();
    const existsInQueue = queue.find(i => i.name === name);

    if (!existsInQueue) {
      const modalToQueue = {
        name,
        params,
      } as Modal;

      const newQueue: Modal[] = [...queue, modalToQueue];
      dispatch(setQueue(newQueue));
    }
  };
};

const showNextFromQueue = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { queue },
    } = getState();

    if (queue.length) {
      const nextModal = queue[0];
      dispatch(showModal(nextModal.name, nextModal?.params));
    }
  };
};

const removeFromQueue = (name: Modal['name']) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      modals: { queue },
    } = getState();

    const newQueue = queue.filter(i => i.name !== name);
    dispatch(setQueue(newQueue));
  };
};
